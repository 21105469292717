export default {
    header: {
        languagePicker: {
            title: 'Выбрать язык',
            button: 'брать'
        },
        shortVersion: {
            link: 'Краткая инструкция ERES®'
        },
        longVersion: {
            link: 'инструкция ERES®'
        }
    },
    footer: {
        privacy: {
            cookie: 'Мы не используем файлы cookie.',
            title: 'Защита данных'
        },
        partner: {
            title: 'Наши институты-партнеры'
        },
        images: {
            title: 'Использованные изображения'
        }
    },
    sidePanel: {
        basicCourseCard: {
            title: 'руководство',
            content: 'Ознакомьтесь с нашим руководством для быстрого обзора.',
            link: 'Прочитать краткое руководство'
        },
        aboutUs: {
            title: 'О нас:',
            content: 'Институт эмоционального здоровья стремится сделать доступным для людей во всем мире знание о том, что мы обладаем биологической способностью через своё тело растворять многочисленные формы тревоги, стресса, гнева и блокировок, которые мы испытываем. Важной заботой членов организации является предоставление на данной интернетной  странице точных инструкций о том, как применять этот ресурс в повседневной жизни. В Германии, Австрии и немецкоязычной Швейцарии этот осознанный подход, заключающийся в применении биологического ресурса, называется "Эмоциональное разрешение", сокращенно ERES®.',
            link: 'Узнать больше',
            tltr: '<p>Институт эмоционального здоровья (IEG) - это некоммерческая ассоциация, чьи сертифицированные преподаватели и тренеры принесли знания ERES® в Германию благодаря многолетнему сотрудничеству с французскими исследователями, физиотерапевтами и врачами. Во Франции природный ресурс ERES® был обнаружен в 1980-х годах и с тех пор интенсивно исследуется и разрабатывается.</p>\n' +
                '<p>Целью ассоциации является содействие эмоциональному здоровью людей и животных во всем мире. Используя биологический ресурс эмоциональной саморегуляции, который доступен всем живым существам, люди могут постепенно растворять свои старые, мешающие им эмоциональные шаблоны. В результате они перестают переносить собственный стресс и страхи на окружающих людей и животных. Повышение спокойствия и радости жизни позволяет людям жить более мирно друг с другом и с животными.</p>\n' +
                '<p>Научная и социальная направленность IEG заключается в том, чтобы понять и передать, как наш мозг создает и поддерживает эмоциональные шаблоны и как они могут быть навсегда разрушены с помощью ERES. Вот несколько идей:</p>\n' +
                '<ul style="margin-left: 2em; list-style-position: inherit">' +
                '<li style="list-style-type: disc;font-weight: bold">Эмоционально спокойный и удовлетворенный ребенок легче учится в школе, лучше ладит с братьями и сестрами, друзьями и в результате чувствует себя более счастливым и беззаботным... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Эмоционально уравновешенные подростки меньше нуждаются в наркотиках и лекарствах, лучше ладят с родителями и учебой и, таким образом, легче находят дорогу в самостоятельное будущее... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Эмоционально спокойные взрослые - более уравновешенные родители, соседи, друзья, коллеги и руководители... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Люди, которые растворяют свои собственные эмоциональные шаблоны и страхи, и таким образом больше не отыгрывают их на своих семьях, коллегах и домашних животных, вносят активный вклад в более мирное мировое сообщество. </li>' +
                '</ul>' +
                '<p>Подробную информацию о происхождении и научной основе эмоционального разрешения см. на сайте: <a href="https://www.emotionalhealthinstitute.org/emotional-resolution">https://www.emotionalhealthinstitute.org/emotional-resolution</a></p>\n' +
                '<p>В целях лучшей читабельности мы обошлись без выделения по половому признаку. Конечно, мы всегда обращаемся ко всем женщинам и мужчинам и просим наших читателей отнестись к этому с пониманием.</p>'
        },
        helpCard: {
            title: 'Нужна ли вам помощь?',
            content2: 'Если вам нужна поддержка, потому что вы не хотите пробовать это самостоятельно или ваши проблемы кажутся вам очень глубокосидящими, здесь вы найдете профессиональную поддержку.',
            content: 'Боишься ли ты экзаменов? Есть ли у тебя экзистенциальный страх? Ты боишься темноты, собак или пауков...?',
            link: '@:sidePanel.basicCourseCard.link',
            linkEres: 'профессиональную поддержку <i class="material-icons">open_in_new</i>',
            url: 'https://www.eres-academia.de/'
        }
    },
    welcome: {
        title: {
            value: 'Ты уже знал, ...',
            value1: 'что ты можешь растворить страх, стресс и запреты через свое тело?'
        },
        content: {
            value: 'Твоё тело также знает, как сделать это, если вы позволите ему, потому что эта биологическая способность является врожденной у нас, людей, и может принести тебе облегчение в эмоциональных ситуациях.',
            value1: 'Это поможет вам, когда вы почувствуете себя бессильным или в отчаянии, потому что не знаете, как действовать дальше. Или если вы напуганы, расстроены или чувствуете себя парализованным...',
            value2: 'Мы действительно можем через наше тело растворить стресс, гнев и ярость, а также страхи и дискомфорт непосредственно в повседневной жизни. Именно так изначально задумано природой. Но в течение долгого времени из-за нашей культуры мы больше не имеем прямого доступа к этой природной способности. Сегодня мы знаем, как сознательно активировать его. И это совершенно бесплатно!'
        }
    },
    purpose: {
        title: 'Что ты получаешь от этого?',
        content: '<span style="font-size: 1em; font-weight: bold">ВАША ЖИЗНЬ СТАНЕТ ЛЕГЧЕ!</span>'
    },
    purpose2: {
        title: '',
        content: '<ul class="disc">' +
            '<li>Стресс в вашей повседневной жизни уменьшается, и вы становитесь более расслабленным. Кроме того, у вас будет меньше стресса с другими людьми, и вы будете жить более здоровой жизнью. Потому что — это было научно доказано — стресс делает нас больными в долгосрочной перспективе.</li>' +
            '<li>Снятие стресса поможет вам, учитесь ли вы в школе, колледже или университете, работаете ли вы, безработный или пенсионер, заботитесь ли вы о детях, домашнем хозяйстве или семье.</li>' +
            '</ul>'
    },
    howTo: {
        title: 'И как это работает?',
        content: 'Была разработана специальная процедура, чтобы сознательно активировать нашу биологическую способность растворять стресс, страхи и блокировки. Он известен как Эмоциональное разрешение или ERES® и преподается бесплатно Институтом эмоционального здоровья (IEG). Сознательной активации этой способности с помощью ERES® легко научиться – и вам тоже.'
    },
    course: {
        title: 'Процедура шаг за шагом:',
        step1: {
            title: 'Шаг 1: Подготовка',
            preface: 'Чтобы подготовиться, вам нужно убедиться, что ваше тело на самом деле реагирует на страх и стресс.',
            content:
                '<p>Вы когда-нибудь сталкивались с тем, что ваше тело реагирует, например, когда вы злитесь, или вы наблюдали это у других?</p>' +
                '<p>В случае сильного гнева или ярости многие люди чувствуют, что им становится, например, жарко. Кроме того, может краснеть голова, учащаться сердцебиение и дыхание, напрягаться мышцы.</p>' +
                '<p>Если вы злитесь, грустите или испытываете какой-либо иной стресс, попробуйте сами, где в теле вы можете почувствовать, как оно реагирует. Также обратите внимание на любой дискомфорт, который лишь слегка заметен, потому что как слабые, так и сильные реакции показывают начало естественного процесса Эмоционального Разрешения (ERES®).</p>' +
                '<p>Если вы попробовали и можете подтвердить эти ощущения, то далее следует шаг 2.</p>'
        },
        step2: {
            title: 'Шаг 2: Выберите опыт',
            preface: 'Если вы хотите попробовать, как вы можете разрешить неприятные эмоции с помощью ERES, вам нужен определенный опыт стресса из вашей повседневной жизни (гнев, страх, печаль, блокировки...).',
            content:
                '<p>Какой из них вы выбираете? Что вас беспокоит и приводит к стрессу в вашей жизни? Чего вы боитесь? Где вы подавлены?</p>' +
                '<p>Примеры:' +
                '<ul class="disc">' +
                '<li>Вы злитесь из-за того, что кто-то что-то сказал.</li>' +
                '<li>Вы сейчас чувствуете себя подавленным, беспомощным и в унынии.</li>' +
                '<li>Вы боитесь предстоящего экзамена или когда-либо испытывали блэкаут.</li>' +
                '<li>Вы застенчивы и боитесь разговаривать с другими.</li>' +
                '<li>Вы боитесь высоты, полета, пауков, собак или у вас клаустрофобия...</li>' +
                '</ul>' +
                '</p>' +
                '<p>Как только вы окажетесь в ситуации в повседневной жизни, в которой вы испытываете что-то подобное, сначала сознательно отметьте, где ваше тело реагирует неприятно. Эта первая часть тела, где возникает неприятное ощущение, обычно ощущается непосредственно, например, давление в груди, боль в желудке или что-то другое, что лишь немного неудобно. Лучше всего положить руку туда, где вы это чувствуете.</p>'
        },
        step3: {
            title: 'Шаг 3: Внутреннее восприятие',
            preface: 'А теперь закройте глаза! Вы сознательно активируете свою естественную способность растворять страх и стресс, просто наблюдая за тем, что происходит в вашем теле.',
            content:
                '<p><span class="bold">Здесь важно следующее:</span>' +
                '<ul class="disc">' +
                '<li>Ничего не делайте, не двигайтесь! (Будьте пассивны и неподвижны, как статуя). Научитесь просто воспринимать на протяжении всего процесса.</li>' +
                '<li>Полностью отпустите ситуацию, которая раньше вызывала у вас эмоции! Перестаньте думать об этом и просто сосредоточьтесь на ощущениях своего тела.</li>' +
                '</ul>' +
                '</p>' +
                '<p class="bold">Иначе не получится!</p>' +
                '<p>Иногда также необходимо отправиться в место, где вы чувствуете себя в достаточной безопасности, чтобы закрыть глаза на несколько минут и просто наблюдать за ощущениями своего тела.</p>'
        },
        step4: {
            title: 'Шаг 4: Почувствуйте свое тело',
            preface: 'Продолжайте чувствовать исключительно в своём теле! Обратите внимание, где вы можете почувствовать и назвать другую неприятную реакцию тела.',
            content: '<p>Например, это может быть неприятное беспокойство в любом месте тела, жжение или покалывание. Сердцебиение может учащаться, дыхание учащаться или замедляться. Боль, давление, жжение, зуд и т.п. могут возникать в спине или в другом месте. Каждое из этих телесных ощущений, которые вы замечаете вторыми, показывает, что ваша естественная способность тела позволяет неприятным эмоциям или стрессу растворяться. Вы также можете положить руку на это место, чтобы быть более осведомленным об этом.</p>' +
                '<p>Это второе неприятное телесное ощущение, а за ним и другие, присутствуют в каждой стрессовой эмоции, которую мы испытываем в повседневной жизни. Немного потренировавшись, мы воспринимаем их очень быстро, с того момента, как закроем глаза.</p>' +
                '<p>Как только вы попробуете это несколько раз с разными эмоциями и стрессами, вы поймете, что наше тело всегда реагирует на каждую эмоцию.</p>'
        },
        step5: {
            title: 'Шаг 5: Следуйте ощущениям своего тела',
            preface: 'Следите внимательно за дальнейшим развитием в своем теле. Ничего не делайте! Научитесь по-настоящему наблюдать совершенно пассивно! Пусть все ощущения тела меняются сами собой, пока не успокоятся!',
            content: '<p>Они могут возникать последовательно или одновременно. Нормальный процесс заключается в том, что если вы не двигаетесь или не проявляете активности, они развиваются сами по себе! Это не займет много времени. Через две-три минуты, а часто и быстрее, ваше тело снова почувствует себя хорошо, и все реакции организма успокоятся.</p>' +
                '<p>Для этого шага полезно знать:' +
                '<ul class="disc">' +
                '<li>Каждый стресс, каждый страх, каждое «я не могу этого сделать» связано со своим специфическим набором определенных телесных ощущений. Вы можете думать об этом как о фильме, который воспроизводится снова и снова каждый раз, когда вы испытываете определенную эмоцию. Если вы применяете ЭРЕС с разными эмоциями, вы это заметите.</li>' +
                '<li>Большинство возникающих телесных ощущений обычно неприятны только в течение короткого времени и очень быстро меняются. Это также относится к бурным физическим реакциям, которые иногда случаются.</li>' +
                '</ul></p>'
        },
        step6: {
            title: 'Шаг 6:  Когда все успокоится',
            preface: 'Откройте глаза, как только утихнут неприятные телесные ощущения.',
            content: 'Потратьте несколько секунд, чтобы полностью вернуться наружу. Снова осознайте свое окружение и то, где вы находитесь в данный момент. Как вы себя чувствуете?'
        },
        step7: {
            title: 'Шаг 7: Всё исчезло?',
            preface: 'Сделайте еще одну проверку, чтобы убедиться, что все исчезло: некоторые эмоциональные проблемы требуют двух проходов.',
            content: '<p>Спросите себя, вспоминая о том, что только что вас напрягало: «Что меня пугало, где я был заблокирован или в отчаянии, что я чувствую по этому поводу сейчас?»</p>' +
                '<p>Вам только лучше? Эмоции стали слабее, но не исчезли?</p>' +
                '<p>В этом случае сделайте еще один точно такой же проход. Спросите себя еще раз: «Где я чувствую что-то некомфортное в своем теле?» и повторите шаги 2-5 так же, как в первый раз.</p>' +
                '<p>Если все в порядке и вы чувствуете себя эмоционально уравновешенным после первой пробежки, вы успешно применили свою естественную способность к регулированию. Поздравляем!</p>' +
                '<p>Тогда вы в повседневной жизни заметите, разрешилось ли на самом деле все, что было связано с первоначальным стрессом, как только вы спокойно отреагируете в подобной ситуации.</p>' +
                '<p>Возможно, сейчас появляются другие глубинные эмоции, которые также неудобны для вас. Чтобы у вас не сложилось впечатление, что ЭРЕС у вас не сработал, важно назвать эмоции, с которыми вы делаете ЭРЕС. Так вам будет легче отличить их от других эмоций, которые вы испытываете в подобных стрессовых ситуациях. Также полезно записывать их.</p>' +
                '<p>Каждая дополнительная неприятная эмоция, которую мы испытываем, требует своего применения ERES. Как только вы осознаете эффективность ERES, имеет смысл использовать его как можно чаще. Чем чаще вы будете это делать, тем спокойнее, безбоязненнее свободнее и смелее будете себя чувствовать!</p>'
        },
        tips: {
            title: 'Советы – если у вас не получилось с первого раза',
            1: {
                title: 'Вы испытываете очень сильные, неприятные телесные ощущения',
                content: '<p>Иногда очень сильные эмоции вызывают это, даже на короткое время. Это могут быть: сильное покалывание, дрожь, одышка, головокружение или тошнота...</p>' +
                    '<p>Наша спонтанная реакция на это часто заключается в том, что мы концентрируемся на этих неприятных телесных чувств или открываем глаза. Жаль, потому что это прерывает процесс. Чтобы этого не случилось с вами, вам важно в этом случае <span class="bold">лишь пассивно наблюдать</span>. Для этого нужно знать, что даже сильные неприятные ощущения меняются и успокаиваются в течение нескольких секунд, если просто дать им измениться.</p>'
            },
            2: {
                title: 'Что подразумевается под «пассивным наблюдением»?',
                content: 'На протяжении всего этого руководства по ERES® вам постоянно говорят оставаться пассивными и действительно ничего не делать, пока ваши глаза закрыты. Суть в том, чтобы ни коим образом не влиять на ход телесных ощущений: ни через активное дыхание, ни через обдумывание исходной ситуации или других подобных ситуаций. Любое движение также является формой становления активным. В большинстве случаев мы делаем это бессознательно, чтобы смягчить или избежать неприятных ощущений. В этом нет необходимости, так как в пассивном режиме все равно все очень быстро меняется и успокаивается само собой. Любое влияние замедляет или предотвращает естественный процесс ERES®.'
            },
            3: {
                title: 'Сознательное восприятие внешнего шума',
                content: '<p>Для многих из нас самый простой способ использовать ERES® — это оставить часть своего внимания снаружи. <span class="bold">Для этого обращайте внимание на окружающие вас звуки.</span> Сильные физические ощущения, которые иногда возникают, изменяются посредством этого быстрее.</p><p>' +
                    'Почему это происходит? Если мы одновременно с закрытыми глазами воспринимаем то, что происходит внутри нашего тела и в нашем окружении, то велика вероятность того, что эмоциональные телесные реакции, хранящиеся в нашей телесной памяти, как в фильме, могут беспрепятственно следовать одна за другой, изменяться и успокаиваться.' +
                    '</p>'
            },
            4: {
                title: 'Вы все время сохраняете контроль над происходящим',
                content: '<p>если возникающие физические ощущения слишком неприятны для вас, или если вы чувствуете себя не совсем комфортно там, где находитесь с закрытыми глазами, вы можете в любой момент снова открыть глаза и таким образом закончить процесс.</p>' +
                    '<p>Как только эмоция, которую вы хотели разрешить с помощью ERES®, снова появится в вашей повседневной жизни, вы можете снова попытаться решить ее напрямую.</p>'
            }
        },
        next: {
            title: 'Для того, чтобы вы еще больше познакомились с ERES®:',
            content: '<p>Стоит записать каждый опыт, который вы испытали при использовании Эмоционального Разрешения (ERES®). Достаточно отметить первые три телесных ощущения соответствующей эмоции. Вы обнаружите, что каждая эмоция имеет свою собственную подпись и последовательность и хранится вместе с ней, как в библиотеке в памяти нашего тела.</p>' +
                '<p>Например, создайте для него записную книжку или диктуйте свои впечатления на смартфон.</p>' +
                '<p>И рассказывай дальше. Природа устроена так, что если мы не записывали переживания или не делились ими с другими, то часто напрочь забываем о постоянно разрешившихся страхах, стрессах и комплексах. Мы еще лучше помним полезность этой способности, когда рассказываем другим, как нам удалось справиться с конкретным страхом или стрессом.</p>'
        },
        limits: {
            title: 'Ограничения ERES® и личная ответственность',
            content: '<p>IEG считает, что знание того, как использовать нашу естественную способность самостоятельно справляться с неприятными эмоциями, представляет собой особый шанс. В то же время важно взять на себя личную ответственность за изучение процесса. Если вам нужна поддержка, потому что вы не хотите пробовать это самостоятельно или ваши проблемы кажутся вам очень глубокосидящими, здесь вы найдете профессиональную поддержку:<a href="https://www.emotionalhealthinstitute.org/emotional-resolution">https://www.emotionalhealthinstitute.org/emotional-resolution</a></p>'
        },
        shortVersion: {
            title: 'Краткая инструкция по использованию ERES',
            content: '<ul class="disc"><li>Обращайте внимание на специфические стрессовые ситуации в вашей повседневной жизни (гнев, страх, печаль, блокировки...), чтобы использовать ERES®!</li>' +
                '<li>Как только вы испытаете неприятную эмоцию, помните, что с ней можно использовать ERES® и вы находитесь в безопасном месте, спросите себя: «Где я сейчас это чувствую в своем теле?»</li>' +
                '<li>Если хотите, поместите руку туда, где вы испытываете неприятные телесные ощущения. Это облегчает пассивное ощущение в теле.</li>' +
                '<li>Теперь закройте глаза!</li>' +
                '<li>Где вы замечаете второе неприятное телесное ощущение? Если хочешь, можешь положить туда и руку.</li>' +
                '<li>Ничего не делать!</li>' +
                '<li>Наблюдайте за тем, что происходит в вашем теле и как новые телесные ощущения появляются одно за другим и меняются снова и снова! Вы также можете следить за этими изменениями руками.</li>' +
                '<li>Делайте это до тех пор, пока не успокоятся все телесные ощущения! Это занимает около двух-трех минут; иногда быстрее.</li>' +
                '<li>Когда неприятных телесных ощущений больше не будет, откройте глаза!</li>' +
                '<li>Вернитесь вовне и теперь спросите себя: «Если я снова подумаю о том, что меня только что напрягло, что я чувствую по этому поводу сейчас?»</li>' +
                '<li>Если вы чувствуете себя «только лучше», но эмоция еще не полностью разрешилась, , сделайте второй идентичный проход! Это также начинается с вопроса: «Где я чувствую что-то некомфортное в своем теле?»</li>' +
                '<li>Если после первого раунда все в порядке и вы чувствуете себя эмоционально уравновешенным, вы успешно использовали ERES®. В подобных повседневных ситуациях вы получите подтверждение: на этот раз вы оставались спокойными.</li></ul>'
        }
    },
    disclaimer: {
        title: 'Отказ от ответственности',
        content: '<p>ERES - это эффективная форма первой помощи при стрессе и других неприятных эмоциях, возникающих в повседневной жизни. В то же время он не заменяет никакой формы терапии или визита к врачу. Применение описанной здесь процедуры находится под личной ответственностью пользователя.</p>' +
            '<p>Вся информация предоставляется без гарантий со стороны авторов или IEG. Поэтому любая ответственность IEG или ее агентов за травмы, материальный ущерб или финансовые потери исключается.</p>',
        contentShort: '<p>ERES - это эффективная форма первой помощи при стрессе и других неприятных эмоциях, возникающих в повседневной жизни. В то же время он не заменяет никакой формы терапии или визита к врачу. Применение описанной здесь процедуры находится под личной ответственностью пользователя.</p>'
    },
    support: {
        title: 'Я хотел бы поддержать IEG',
        content: '<p>С помощью этого сайта мы хотели бы сделать руководство ERES доступным для всех людей на их родном языке, а также распространить знания об этом ресурсе с помощью других инициатив. Будучи некоммерческой ассоциацией, мы зависим от пожертвований, чтобы сделать это возможным. Мы будем рады, если вы поддержите нас пожертвованием на следующий счет:</p>' +
            'Получатель: Institut für Emotionale Gesundheit e. V.<br>' +
            'IBAN : DE13 4786 0125 0530 8813 00<br>' +
            'BIC : GENODEM1GTL<br>' +
            '<p>Если вы хотите получить квитанцию о пожертвовании, пожалуйста, не забудьте указать ваше имя и полный адрес, чтобы мы могли отправить ее вам.</p>'
    }
}