<template>
  <header>
    <div class="navbar-fixed">
      <nav>
        <div class="nav-wrapper">
          <a class="sidenav-trigger" data-target="mobile-sidenav" href="#"><i class="material-icons">menu</i></a>
          <ul class="left">
            <li style="margin-left:0.5em"><a class="modal-trigger" href="#about-us-modal">IEG <span
                class="hide-on-med-and-down">- Institut f&uuml;r Emotionale Gesundheit e.V.</span></a></li>
          </ul>
          <ul style="position: absolute; left:35%; transform:translateX(-35%)">
            <li class="logo"><a class="modal-trigger" href="#about-us-modal"><img
                src="../assets/Emotional_Health_Logo.png"></a></li>
          </ul>
          <ul style="position: absolute; right:40%; transform:translateX(40%)">
            <li class="logo"><a class="modal-trigger" href="#about-us-modal"><img
                src="../assets/eres_logo.png"></a></li>
          </ul>
          <ul class="right">
            <li class="hide-on-med-and-down">
              <a class="modal-trigger" href="#basic-course-modal"
                 style="color: #ffab40; text-transform: uppercase; font-size: 1.3em">{{ $t('header.shortVersion.link') }}</a>
            </li>
            <li class="hide-on-large-only">
              <a class="modal-trigger" href="#basic-course-modal" style="color: #ffab40;"><i class="material-icons">library_books</i></a>
            </li>
            <li>
              <language-picker></language-picker>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <ul id="mobile-sidenav" class="sidenav">
      <li>
        <div class="card">
          <div class="card-action">
            <a class="modal-trigger sidenav-close" href="#basic-course-modal">{{ $t('header.shortVersion.link') }}</a>
          </div>
          <div class="card-action">
            <a class="sidenav-close" href="#howTo">{{ $t('header.longVersion.link') }}</a>
          </div>
        </div>
      </li>
      <li class="sidenav-close">
        <AboutUsCard></AboutUsCard>
      </li>
    </ul>
  </header>
</template>

<script>
import LanguagePicker from "@/components/LanguagePicker";
import AboutUsCard from "@/components/AboutUsCard";

export default {
  name: "Header",
  components: {LanguagePicker, AboutUsCard}
}
</script>

<style scoped>
nav {
  background-color: #212C40;
}

nav a.sidenav-trigger {
  display: inline;
}

.logo img {
  max-height: 60px;
}
</style>