<template>
  <div id="imprint-modal" class="modal">
    <div class="modal-header"><a class="right modal-close"><i class="material-icons">close</i></a></div>
    <div class="modal-content">
      <div class="modal-header"><h4><span v-if="this.$i18n.locale !== 'en'">Impressum/</span>Imprint</h4></div>
      <div class="col l6 s12">
        <h5>Institut für Emotionale Gesundheit e. V.</h5>
        <div v-if="this.$i18n.locale !== 'en'">
          <h6>Impressum</h6>
          <p>
            Institut für Emotionale Gesundheit e. V.<br/>
            Adresse: Bussardweg 36, 33659 Bielefeld<br/>
            E-Mail: info@ieg-eres.de<br/>
            Vereinsregister: Amtsgericht Freiburg<br/>
            Vereinsregisternummer: VR 702946<br/>
            Vertretungsberechtigte: Dr. Monika Wilke als Vorstandsvorsitzende<br/>
          </p>
        </div>
        <h6>Imprint</h6>
        <p>
          Emotional Health Institute e. V.<br>
          Address: Bussardweg 36, 33659 Bielefeld, Germany<br>
          E-mail: info@ieg-eres.de<br>
          Register of associations: Freiburg Local Court<br>
          Association registration number: VR 702946<br>
          Authorised representative: Dr. Monika Wilke
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImprintModal"
}
</script>

<style scoped>

</style>