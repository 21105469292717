export default {
    header: {
        languagePicker: {
            title: 'Seleccione el idioma',
            button: 'Toma de posesión'
        },
        shortVersion: {
            link: 'Guía rápida ERES®'
        },
        longVersion: {
            link: 'Guía ERES®'
        }
    },
    footer: {
        privacy: {
            cookie: 'No usamos cookies',
            title: 'Privacy policy'
        },
        partner: {
            title: 'Nuestros institutos asociados'
        },
        images: {
            title: 'Imágenes utilizadas'
        }
    },
    sidePanel: {
        basicCourseCard: {
            title: 'Guía',
            content: 'Consulte nuestra guía para obtener una rápida visión general.',
            link: 'Leer la guía rápida'
        },
        aboutUs: {
            title: 'Sobre nosotros',
            content: 'El Instituto para la Salud Emocional pretende poner a disposición de todas las personas del mundo el conocimiento de que tenemos la capacidad biológica de disolver las múltiples formas de ansiedad, estrés, ira y bloqueos a través del cuerpo. Es una preocupación importante de sus miembros poner a disposición en esta página web instrucciones precisas sobre cómo aplicar este recurso a uno mismo en la vida cotidiana. En Alemania, Austria y la Suiza de habla alemana, el enfoque consciente de la aplicación del recurso biológico Resolución Emocional, se llama ERES® para abreviar.',
            link: 'Más información',
            tltr: '<p>El Instituto para la Salud Emocional (IEG) es una asociación sin ánimo de lucro cuyos formadores y entrenadores certificados han llevado los conocimientos de ERES® a Alemania a través de muchos años de cooperación con investigadores, fisioterapeutas y médicos franceses. En Francia, el recurso natural del ERES® se descubrió en los años 80 y desde entonces se ha investigado y desarrollado intensamente.</p>\n' +
                '<p>El objetivo de la asociación es promover la salud emocional de personas y animales en todo el mundo. Utilizando el recurso biológico de la autorregulación emocional, que está a disposición de todos los seres vivos, las personas pueden disolver poco a poco sus viejos y perturbadores patrones emocionales. Como resultado, ya no transfieren su propio estrés y sus miedos a sus compañeros y a los animales. El aumento de serenidad y de alegría de vivir permite a las personas vivir más tranquilamente entre ellas y con los animales.</p>\n' +
                '<p>El enfoque científico y social del IEG es comprender y transmitir cómo nuestro cerebro crea y mantiene patrones emocionales y cómo pueden ser disueltos permanentemente mediante el uso de ERES. He aquí algunas ideas:</p>\n' +
                '<ul style="margin-left: 2em; list-style-position: inherit">' +
                '<li style="list-style-type: disc;font-weight: bold">Un niño emocionalmente tranquilo y contento aprende más fácilmente en la escuela, se lleva mejor con sus hermanos y amigos, y se siente más feliz y despreocupado como resultado... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Los adolescentes emocionalmente equilibrados consumen menos drogas y medicamentos, se llevan mejor con sus padres y mejoran su rendimiento académico, por tanto, encuentran más fácilmente su camino hacia un futuro autónomo... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Los adultos emocionalmente tranquilos son padres, vecinos, amigos y colegas más equilibrados y líderes... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Las personas que disuelven sus propios patrones emocionales y sus miedos sin exteriorizarlo en sus familias, amigos y/o mascotas, contribuyen activamente a una comunidad mundial más pacífica.</li>' +
                '</ul>' +
                '<p>Para obtener información detallada sobre el origen y la base científica de la Resolución Emocional, consulte: <a href="https://www.emotionalhealthinstitute.org/emotional-resolution">https://www.emotionalhealthinstitute.org/emotional-resolution</a></p>\n' +
                '<p>Para una mejor legibilidad, nos hemos abstenido de utilizar el género. Por supuesto, siempre nos referimos a todas las mujeres y hombres y pedimos a nuestros lectores su comprensión.</p>'
        },
        helpCard: {
            title: '¿Necesitas ayuda?',
            content2: 'Si no quieres intentarlo por tu cuenta o tus problemas parecen muy profundos, puedes solicitar el apoyo profesional de ERES®.',
            content: '¿Tienes nervios por el examen? ¿Tienes miedo existencial? ¿Tienes miedo a la oscuridad, a los perros o a las arañas…?',
            link: '@:sidePanel.basicCourseCard.link',
            linkEres: 'Apoyo profesional <i class="material-icons">open_in_new</i>',
            url: 'https://www.eres-academia.de/'
        }
    },
    welcome: {
        title: {
            value: 'Ya sabías que...',
            value1: '¿Puedes disolver el miedo, el estrés o cualquier emoción de bloqueo a través de tu cuerpo?'
        },
        content: {
            value: 'Esto se debe a que el cuerpo sabe cómo hacerlo si se lo permites, ya que es una capacidad innata en nosotros, los humanos. Por lo tanto, esta habilidad biológica puede aliviarte en situaciones de emergencia emocional.',
            value1: 'Por ejemplo, cuando te sientes impotente o desesperado porque no sabes qué hacer; cuando estás asustado, enfadado, paralizado, etc. ...',
            value2: 'De hecho, podemos llegar a disolver cualquier emoción como el estrés, la ira, el miedo o malestar a través de nuestro cuerpo, en cualquier momento de nuestra vida cotidiana. Esto se debe a que originalmente la naturaleza lo concibió así para nosotros, pero desde hace mucho tiempo, debido a nuestra cultura, ya no tenemos acceso directo a esta capacidad natural; sin embargo, hoy sabemos cómo activarla conscientemente. Y es completamente gratis.'
        }
    },
    purpose: {
        title: '¿Qué consigues con ello?',
        content: '<span style="font-size: 1em; font-weight: bold">¡TU VIDA SE VUELVE MÁS FÁCIL!</span>'
    },
    purpose2: {
        title: '',
        content: '<ul class="disc">' +
            '<li>El estrés se reduce significativamente en tu vida diaria, lo que te hace vivir más relajado.  Asimismo, tus relaciones con los demás serán menos estresantes y llevarás una vida más sana, ya que - y esto está científicamente demostrado- el estrés nos hace enfermar a largo plazo.</li>' +
            '<li>La reducción del estrés te ayudará independientemente de la actividad que estés desempeñando, puesto que te será útil tanto si eres estudiante, trabajador, desempleado, jubilado o te dedicas al cuidado de tu hogar.</li>' +
            '</ul>'
    },
    howTo: {
        title: '¿Cómo funciona? ',
        content: 'Se ha desarrollado un enfoque especial para activar conscientemente nuestra capacidad biológica para disolver el estrés, los miedos y los bloqueos. Esto se conoce como Resolución Emocional o ERES® y lo imparte gratuitamente el Instituto para la Salud Emocional (IEG). La activación consciente de esta capacidad a través de ERES® es fácil de aprender, también para ti'
    },
    course: {
        title: 'Procedimiento',
        step1: {
            title: 'PASO 1: PREPARACIÓN',
            preface: 'Para prepararte, debes experimentar que tu cuerpo reacciona realmente a la ansiedad o estrés.',
            content:
                '<p>¿Alguna vez has experimentado cómo reacciona tu cuerpo cuando estás enfadado o lo has observado en otros?</p>' +
                '<p>Por ejemplo, hay personas que cuando están muy enfadadas sienten que se calientan. Otras pueden llegar a sentir que su cara se enrojece, que sus músculos se tensan o que su corazón y respiración se acelera.</p>' +
                '<p>Si estás enfadado, triste o estresado, prueba en identificar por ti mismo qué parte del cuerpo ha sido alterada por esta emoción; de esa forma, podrás identificar cómo estás reaccionando ante ese estímulo. Además, presta atención a las reacciones corporales que son ligeramente perceptibles, porque tanto las reacciones débiles como las fuertes muestran el comienzo del proceso natural de Resolución Emocional (ERES®).</p>' +
                '<p>Una vez que lo hayas probado y puedas confirmar esta experiencia, sigue el paso 2.</p>'
        },
        step2: {
            title: 'PASO 2: ELIGIR UNA EXPERIENCIA',
            preface: 'Si quieres probar cómo puedes disolver las emociones desagradables con ERES, necesitas una situación concreta de tu vida cotidiana que te haya producido alguna sensación negativa (ira, miedo, tristeza, bloqueos, etc.)',
            content:
                '<p>Para saber cuál elegir puedes plantearte las siguientes preguntas: ¿qué me perturba y/o estresa en mi vida? ¿De qué tengo miedo? ¿Dónde me siento bloqueado?</p>' +
                '<p>Algunas situaciones pueden ser las siguientes:' +
                '<ul class="disc">' +
                '<li>Estás enfadado por algo que ha dicho alguien.</li>' +
                '<li>Te sientes abatido, impotente y desanimado tras una noticia.</li>' +
                '<li>Tienes miedo de un próximo examen o ya has experimentado un bloqueo.</li>' +
                '<li>Eres tímido y tienes miedo de hablar con los demás.</li>' +
                '<li>Tienes miedo a las alturas, a volar, a las arañas, a los perros o quizás tienes claustrofobia.</li>' +
                '</ul>' +
                '</p>' +
                '<p>Una vez encuentres una situación en la que experimentes algo así, sé consciente de tu emoción y ponle nombre. Ahora es especialmente importante que no quieras deshacerte de la emoción, sino que la aceptes y la acojas amablemente, por muy fuerte o desagradable que sea en este momento. Ahora observa dónde se manifiesta la emoción en tu cuerpo. La primera parte, cuando se nota en el cuerpo dicha sensación, suele ser inmediata y lo mejor es colocar la mano donde se percibe. Por ejemplo, presión en el pecho, dolor en el estómago o cualquier otra cosa mínimamente desagradable. </p>'
        },
        step3: {
            title: 'PASO 3: PERCEPCIÓN INTERIOR',
            preface: 'Ahora cierra los ojos. Activa conscientemente tu capacidad natural de disolver el miedo y el estrés observando solo lo que está presente en tu cuerpo.\n',
            content:
                '<p><span class="bold">Importante:</span>' +
                '<ul class="disc">' +
                '<li> ¡No hagas nada, no te muevas! (Permanece tan pasivo e inmóvil como una estatua).  Aprende simplemente a percibir la sensación durante todo el proceso y aprende también a aceptar y acoger cada sensación física que surja.</li>' +
                '<li>Deja de lado por completo la situación que había desencadenado tu emoción. No pienses más en ello y mantén tu atención exclusivamente en las sensaciones de tu cuerpo.</li>' +
                '</ul>' +
                '</p>' +
                '<p class="bold">De lo contrario, ¡no funciona!</p>' +
                '<p>A veces también es necesario ir a un lugar en el que te sientas lo suficientemente seguro como para cerrar los ojos en silencio durante unos minutos y limitarte a notar las sensaciones de tu cuerpo.</p>'
        },
        step4: {
            title: 'PASO 4: SIENTE TU CUERPO',
            preface: '¡Continúa sintiendo tu cuerpo exclusivamente! Observa dónde puedes sentirlo y nombrar otra reacción corporal desagradable.',
            content: '<p>Por ejemplo, puede ser desagradable la sensación de hormigueo, quemazón o de picor en alguna parte del cuerpo. Igualmente, los latidos del corazón pueden ser más rápidos, la respiración más rápida o lenta, e incluso puede aparecer dolor, presión, ardor o algo similar en la espalda u otra parte. Cada una de estas sensaciones corporales que notas después de la inicial, demuestran que tu capacidad natural para dejar que se disuelvan está actualmente activa. También puedes poner la mano en este punto para ser más consciente de él.</p>' +
                '<p>La segunda sensación corporal desagradable, y otras que la siguen, están presentes en todas las emociones estresantes que experimentamos en la vida cotidiana. Con un poco de práctica las percibimos más rápidamente, llegando a reconocerlas con tan solo cerrar los ojos.</p>' +
                '<p>Una vez que hayas probado esto varias veces con diferentes emociones y situaciones de estrés, te darás cuenta de que, efectivamente, nuestro cuerpo siempre reacciona a cada emoción.</p>'
        },
        step5: {
            title: 'PASO 5: SIGUE LAS SENSACIONES DE TU CUERPO',
            preface: 'Sigue con atención el desarrollo posterior en tu cuerpo. ¡No hagas nada! Aprende a observar de forma totalmente pasiva. Deja que todas las sensaciones corporales cambien por sí solas hasta que se hayan calmado.',
            content: '<p>Pueden ocurrir una tras otra o simultáneamente. La secuencia normal es que -si no te mueves ni te activas de ninguna manera- ¡evolucionarán por sí solas! No se necesita mucho tiempo. Al cabo de dos o tres minutos -a menudo más rápido- tu cuerpo volverá a sentirse bien y todas las reacciones corporales se habrán calmado.</p>' +
                '<p>Para este paso es útil saber:' +
                '<ul class="disc">' +
                '<li>Cada estrés, cada miedo, cada "no puedo hacer esto" está asociado a su propia colección específica de determinadas sensaciones corporales. Puedes pensar en ello como una película que se reproduce una y otra vez cada vez que experimentas una determinada emoción. Si utilizas ERES con diferentes emociones, lo notarás.</li>' +
                '<li>La mayoría de las sensaciones corporales que se producen suelen ser brevemente desagradables y cambian muy rápidamente. si las aceptas amablemente y las acoges en tu interior.\n' +
                ' Esto también se aplica a las reacciones corporales violentas que a veces surgen.</li>' +
                '</ul></p>'
        },
        step6: {
            title: 'PASO 6: CUANDO TODO SE HA CALMADO',
            preface: 'Abre los ojos en cuanto se hayan calmado todas las sensaciones corporales desagradables. ',
            content: 'Tómate unos segundos para llegar de nuevo al exterior. Vuelve a ser consciente de tu entorno y dónde estás ahora mismo. ¿Cómo te sientes?'
        },
        step7: {
            title: 'PASO 7: ¿SE HA RESUELTO TODO?',
            preface: 'Haz otra comprobación para ver si todo se ha resuelto: algunos problemas emocionales necesitan dos procesos.',
            content: '<p>Hazte la siguiente pregunta: "Si vuelvo a pensar en lo que me estresó o asustó hace un momento, en lo que me bloqueó o desesperó, ¿cómo me siento ahora?</p>' +
                '<p>¿Te sientes solo "mejor"? ¿La emoción es más débil pero no ha desaparecido?</p>' +
                '<p>Si es así, haz otro proceso con exactamente el mismo recorrido. Pregúntate de nuevo: "¿En qué otra parte de mi cuerpo se manifiesta mi emoción?". y repite los pasos 2-5 exactamente como lo hiciste la primera vez. </p>' +
                '<p>Si todo va bien y te sientes emocionalmente equilibrado después del primer proceso, has utilizado con éxito tu capacidad natural de regulación. Enhorabuena. A partir de entonces notarás en tu vida cotidiana como todo lo relacionado con el estrés original se ha resuelto realmente, ya que reaccionarás con calma ante una situación similar.</p>' +
                '<p>Sin embargo, es posible que surjan otras emociones subyacentes que también te resulten desagradables. Por lo tanto, y para evitar que tengas la impresión de que el ERES no ha funcionado contigo, es importante nombrar o anotar las emociones con las que lo haces, puesto que te ayudará a distinguirlas de otras emociones que experimentas en situaciones de estrés similares.</p>' +
                '<p>Cualquier otra emoción desagradable que experimentemos necesita su propia aplicación de ERES.</p>' +
                '<p>Una vez reconocida la eficacia de este método, es útil utilizarlo con la mayor frecuencia posible. Cuanto más a menudo lo hagas, más tranquilo, intrépido, libre y valiente te sentirás.</p>'
        },
        tips: {
            title: 'Consejos - si no funcionó la primera vez',
            1: {
                title: 'EXPERIMENTAS SENSACIONES CORPORALES DESAGRADABLES MUY INTENSAS',
                content: '<p>A veces, cuando se experimentan emociones muy intensas, aparecen sensaciones corporales muy fuertes durante un breve periodo de tiempo. Pueden ser: una fuerte punzada, temblores, falta de aire, mareos o náuseas…</p>' +
                    '<p>Nuestra reacción espontánea a esto es, a menudo, centrarnos en estas reacciones corporales desagradables o abrir los ojos. Es una pena, porque al hacerlo interrumpimos el proceso. Para evitar que esto te ocurra, es importante que observes <span class="bold">pasivamente</span> también en este caso. Para ello, debes saber que incluso las sensaciones desagradables más intensas cambiarán y se calmarán en pocos segundos, si simplemente las dejas cambiar</p>'
            },
            2: {
                title: '¿QUÉ SE ENTIENDE POR "OBSERVAR PASIVAMENTE"?',
                content: 'En esta guía de ERES® se te dice repetidamente que permanezcas pasivo y que no hagas nada mientras tengas los ojos cerrados. Se trata de no influir en el curso de las sensaciones del cuerpo de ninguna manera: ni respirando activamente, ni pensando en la situación inicial o en otras situaciones similares. Cualquier movimiento es también una forma de activarse. La mayoría de las veces lo hacemos inconscientemente para disminuir o evitar las sensaciones desagradables. Esto no es necesario porque, de todas formas, todo cambia muy rápidamente en el modo pasivo y se calma por sí mismo. Cualquier forma de influencia ralentiza o impide el proceso natural de ERES®.'
            },
            3: {
                title: 'SÉ CONSCIENTE DE LOS RUIDOS EXTERNOS',
                content: '<p>La aplicación de ERES® es más fácil para muchos de nosotros si dejamos parte de nuestra atención en el exterior. Para ello, observa conscientemente los ruidos de tu entorno. Incluso las sensaciones corporales, en ocasiones intensas, cambian más rápidamente de esta manera.</p><p>' +
                    '¿Y cuál es la razón de esto? La respuesta se encuentra en que si con los ojos cerrados somos conscientes de lo que ocurre dentro de nuestro cuerpo <span class="bold">y</span> en nuestro entorno, lo más probable es que las reacciones corporales vinculadas con nuestras emociones se sucedan sin alteraciones, cambien y puedan calmarse.' +
                    '</p>'
            },
            4: {
                title: 'SIEMPRE TIENES EL CONTROL DE LO QUE OCURRE',
                content: '<p>Si las sensaciones corporales que se producen te resultan demasiado incómodas, o no te sientes del todo cómodo en el lugar en el que estás con los ojos cerrados, puedes volver a abrir los ojos en cualquier momento y terminar así el proceso. </p>' +
                    '<p>En cuanto la emoción que querías resolver con ERES® vuelva a aparecer en tu vida cotidiana, es posible un nuevo intento de resolverla directamente.</p>'
            }
        },
        next: {
            title: 'Para hacerte aún más familiar con ERES®:',
            content: '<p>Merece la pena anotar todas las experiencias que tengas con la aplicación de la Resolución Emocional (ERES®). Basta con anotar las tres primeras sensaciones corporales de cada emoción para que te des cuenta de que cada emoción tiene su propia forma y secuencia y que se almacena en nuestra memoria corporal como en una biblioteca. Por ejemplo, crea un cuaderno para ello o dicta tus experiencias en tu Smartphone. Y compártelo con otras personas. La naturaleza lo ha dispuesto de tal manera que a menudo olvidamos permanentemente los miedos, el estrés y las inhibiciones resueltas si no hemos escrito las experiencias o las hemos compartido con otros. La utilidad de esta habilidad se recuerda aún mejor cuando contamos a los demás cómo lo hicimos para disolver un miedo o estrés concreto.</p>'
        },
        limits: {
            title: 'Los límites de ERES® y responsabilidad personal',
            content: '<p>Desde el punto de vista del IEG, el conocimiento de la aplicación de nuestra capacidad natural para poder disolver nosotros mismos las emociones desagradables representa una oportunidad especial. Al mismo tiempo, es importante asumir la responsabilidad personal de aprender el proceso. Si no quieres intentarlo por tu cuenta o tus problemas parecen muy profundos, puedes solicitar el apoyo profesional de ERES®:</p>'
        },
        shortVersion: {
            title: 'Breves instrucciones para el uso de ERES®',
            content: '<ul class="disc"><li>Presta atención a las experiencias concretas de estrés en tu vida cotidiana (ira, miedo, tristeza, bloqueos...) para aplicar ERES®.</li>' +
                '<li>En cuanto experimentes una emoción desagradable, recuerda que puedes utilizar ERES® con ella y, encontrándote en un lugar seguro, pon nombre a la emoción y salúdala con amabilidad. Luego hazte la pregunta:\n' +
                '"¿Dónde la siento en mi cuerpo en este momento?". Acoge también con benevolencia cualquier otra sensación corporal que surja.</li>' +
                '<li>Si quieres pon una mano en el lugar donde percibes una sensación corporal desagradable. Esto facilita la detección pasiva en el cuerpo.</li>' +
                '<li>Ahora, ¡cierra los ojos!</li>' +
                '<li>¿Dónde notas una segunda sensación corporal desagradable? Si quieres, coloca también una mano en este lugar.</li>' +
                '<li>No hagas nada!</li>' +
                '<li>Observa con benevolencia lo que ocurre en tu cuerpo y cómo las nuevas sensaciones corporales aparecen una tras otra y cambian una y otra vez. También puedes seguir estos cambios con las manos.</li>' +
                '<li>Hazlo hasta que todas las sensaciones corporales se hayan calmado. Esto lleva unos dos o tres minutos; a veces va más rápido.</li>' +
                '<li>Cuando no haya más sensaciones corporales desagradables, ¡abre los ojos!</li>' +
                '<li>Vuelve al exterior y ahora pregúntate: "Si vuelvo a pensar en lo que me acaba de estresar, ¿cómo me siento ahora?</li>' +
                '<li>Si te sientes simplemente "mejor", pero la emoción aún no se ha resuelto del todo, ¡haz un segundo turno idéntico! Acoge de nuevo la emoción y hazte la pregunta: "¿Dónde la siento en mi cuerpo?".</li>' +
                '<li>Si después de la primera sesión todo va bien y te sientes emocionalmente equilibrado, has utilizado ERES® con éxito. En situaciones cotidianas similares experimentarás la confirmación: esta vez has mantenido la calma.</li></ul>'
        }
    },
    disclaimer: {
        title: 'Limite de responsabilidad',
        content: '<p>ERES es una forma eficaz de primeros auxilios cuando el estrés y otras emociones desagradables surgen en la vida cotidiana. Al mismo tiempo, no sustituye a ninguna forma de terapia o visita al médico. El uso del procedimiento descrito aquí es bajo la responsabilidad del usuario.</p>' +
            '<p>Toda la información se proporciona sin garantía por parte de los autores o del IEG. Por lo tanto, queda excluida cualquier responsabilidad del IEG o de sus representantes por daños personales, materiales o económicos.</p>',
        contentShort: '<p>ERES es una forma eficaz de primeros auxilios cuando el estrés y otras emociones desagradables surgen en la vida cotidiana. Al mismo tiempo, no sustituye a ninguna forma de terapia o visita al médico. El uso del procedimiento descrito aquí es bajo la responsabilidad del usuario.</p>'
    },
    support: {
        title: 'Quisiera apoyar al IEG',
        content: '<p>Con este sitio web, queremos poner el manual de ERES a disposición de todas las personas en su propio idioma y también difundir el conocimiento de este recurso con otras iniciativas. Como asociación sin ánimo de lucro, dependemos de las donaciones para hacerlo posible. Estaríamos encantados de que nos apoyaras con una donación a la siguiente cuenta:</p>' +
            'Beneficiario: Institut für Emotionale Gesundheit e. V.<br>' +
            'IBAN : DE13 4786 0125 0530 8813 00<br>' +
            'BIC : GENODEM1GTL<br>' +
            '<p>Si deseas un recibo de donación, asegúrate de incluir tu nombre y dirección completa con la transferencia para que podamos enviártelo.</p>'
    }
}