<template>
  <div id="help-card" class="card">
    <div class="card-content">
      <span class="card-title">{{ $t('sidePanel.helpCard.title') }}</span>
      <p v-if="!useVariant">{{ $t('sidePanel.helpCard.content') }}</p>
      <p v-if="useVariant">{{ $t('sidePanel.helpCard.content2') }}</p>
    </div>
    <div v-if="!shortVersionDisabled" class="card-action">
      <a class="modal-trigger" href="#basic-course-modal">{{ $t('sidePanel.helpCard.link') }}</a>
    </div>
    <div v-if="!eresDisabled" class="card-action">
      <a :href="$t('sidePanel.helpCard.url')" target="_blank" v-html="$t('sidePanel.helpCard.linkEres')"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpCard",
  props: {
    eresDisabled: Boolean,
    shortVersionDisabled: Boolean,
    useVariant: Boolean
  }
}
</script>

<style scoped>
.sidepanel .card .card-content {
  padding: 24px 12px 24px 16px;
}

@media only screen and (max-device-width: 992px) {
  .card {
    margin: 0 0.75rem;
  }
}
</style>