import Vue from 'vue'
import App from './App.vue'
import 'materialize-css/dist/js/materialize.min'
import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'
import './assets/css/main.css';
import VueI18n from 'vue-i18n';
import langDe from './languages/de.js';
import langFr from './languages/fr.js';
import langEn from './languages/en.js';
import langUa from './languages/ua.js';
import langRu from './languages/ru.js';
import langEs from './languages/es.js';

Vue.use(VueI18n);
Vue.config.productionTip = false
const messages = {
    de: langDe,
    fr: langFr,
    en: langEn,
    ru: langRu,
    ua: langUa,
    es: langEs
};

const i18n = new VueI18n({
    locale: 'de',
    messages
});
new Vue({
    render: h => h(App),
    i18n
}).$mount('#app')
