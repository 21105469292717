<template>
  <div id="limitations-modal" class="modal">
    <div class="modal-header"><a class="right modal-close"><i class="material-icons">close</i></a></div>
    <div class="modal-content">
      <div class="modal-header"><h4><span v-if="this.$i18n.locale !== 'en'">Haftungsbeschränkung/</span>Limitation of
        liability</h4></div>
      <div v-if="this.$i18n.locale !== 'en'">
        <h5>Haftungsbeschränkung für eigene Inhalte</h5>
        Die Inhalte unserer Webseiten wurden sorgfältig und nach bestem Gewissen erstellt. Gleichwohl kann für die
        Aktualität, Vollständigkeit und Richtigkeit sämtlicher Seiten keine Gewähr übernommen werden. Gemäß § 7 Abs. 1
        TMG sind wir als Diensteanbieter für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Wir sind als Diensteanbieter nach den §§ 8 bis 10 TMG jedoch nicht verpflichtet, übermittelte
        oder gespeicherte fremde Informationen zu überwachen. Ab dem Zeitpunkt der Kenntnis einer konkreten
        Rechtsverletzung erfolgt eine umgehende Entfernung dieser Inhalte durch uns. Eine diesbezügliche Haftung kann
        erst ab dem Zeitpunkt der Kenntniserlangung übernommen werden.

        <h5>Haftungsbeschränkung für externe Links</h5>
        Unsere Webseite enthält Verknüpfungen zu Webseiten Dritter (sog. „externe Links“). Da wir auf deren Inhalte
        keinen Einfluss haben, kann für die fremden Inhalte keine Gewähr übernommen werden. Für die Inhalte und
        Richtigkeit der Informationen ist stets der jeweilige Informationsanbieter der verlinkten Webseite
        verantwortlich. Zum Zeitpunkt der Verlinkung waren keine Rechtsverstöße erkennbar. Sobald uns eine
        Rechtsverletzung bekannt wird, werden wir den jeweiligen Link umgehend entfernen.

      </div>
      <h5>Limitation of liability for own contents</h5>
      The contents of our website have been prepared carefully and to the best of our knowledge. Nevertheless, no
      guarantee can be given for the topicality, completeness and correctness of all pages. Pursuant to Section 7,
      Paragraph 1 of the German Telemedia Act (TMG), as a service provider we are responsible for our own content on
      these pages in accordance with general legislation. However, as a service provider we are not obliged to monitor
      transmitted or stored third-party information in accordance with §§ 8 to 10 TMG. As soon as we become aware of a
      concrete infringement of the law, we immediately remove this content. Liability in this regard can only be assumed
      from the point in time at which knowledge is obtained.

      <h5>Limitation of liability for external links</h5>
      Our website contains links to third-party websites (so-called "external links"). As we have no influence on their
      content, we cannot accept any liability for the external content. The respective information provider of the
      linked website is always responsible for the content and accuracy of the information. At the time of linking, no
      legal violations were apparent. As soon as we become aware of a legal violation, we will remove the respective
      link immediately.
    </div>
  </div>
</template>

<script>
export default {
  name: "LimitationsModal"
}
</script>

<style scoped>

</style>