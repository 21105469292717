export default {
    header: {
        languagePicker: {
            title: 'Виберіть свою мову',
            button: 'брати'
        },
        shortVersion: {
            link: 'Коротка інструкція ERES®'
        },
        longVersion: {
            link: 'Iнструкція ERES®'
        }
    },
    footer: {
        privacy: {
            cookie: 'Ми не використовуємо файли cookie',
            title: 'Захист даних'
        },
        partner: {
            title: 'Наші партнерські інститути'
        },
        images: {
            title: 'Використані зображення'
        }
    },
    sidePanel: {
        basicCourseCard: {
            title: 'Керівництво',
            content: 'Перегляньте наш посібник, щоб отримати короткий огляд.',
            link: 'Прочитайте Короткий посібник'
        },
        aboutUs: {
            title: 'Про нас',
            content: 'Інститут емоційного здоров\'я прагне зробити доступним для людей у ​​всьому світі знання про те, що ми маємо біологічну здатність через своє тіло розчиняти численні форми тривоги, стресу, гніву та блокувань, які ми відчуваємо. Важливою турботою членів організації є надання на даній веб-сторінці точних інструкцій про те, як застосовувати цей ресурс у повсякденному житті. У Німеччині, Австрії та німецькомовній Швейцарії цей усвідомлений підхід, що полягає у застосуванні біологічного ресурсу, називається "Емоційний дозвіл", скорочено ERES®.',
            link: 'Вчи більше',
            tltr: '<p>Інститут емоційного здоров\'я (IEG) - це некомерційна асоціація, чиї сертифіковані викладачі та тренери принесли знання ERES® у Німеччину завдяки багаторічній співпраці з французькими дослідниками, фізіотерапевтами та лікарями. У Франції природний ресурс ERES® був виявлений у 1980-х роках і з того часу інтенсивно досліджується та розробляється.</p>' +
                '<p>Метою асоціації є сприяння емоційному здоров\'ю людей та тварин у всьому світі. Використовуючи біологічний ресурс емоційної саморегуляції, який доступний усім живим істотам, люди можуть поступово розчиняти свої старі, що заважають їм емоційні шаблони. В результаті вони перестають переносити власний стрес та страхи на оточуючих людей та тварин. Підвищення спокою та радості життя дозволяє людям жити мирніше один з одним і з тваринами.</p>' +
                '<p>Наукова та соціальна спрямованість IEG полягає в тому, щоб зрозуміти та передати, як наш мозок створює та підтримує емоційні шаблони та як вони можуть бути назавжди зруйновані за допомогою ERES. Ось кілька ідей:</p>' +
                '<ul style="margin-left: 2em; list-style-position: inherit">' +
                '<li style="list-style-type: disc;font-weight: bold">Емоційно спокійна і задоволена дитина легше вчиться в школі, краще ладнає з братами та сестрами, друзями і в результаті почувається більш щасливою та безтурботною... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Емоційно врівноважені підлітки менше потребують наркотиків і ліків, краще ладнають із батьками та навчанням і, таким чином, легше знаходять дорогу в самостійне майбутнє... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Емоційно спокійні дорослі - більш урівноважені батьки, сусіди, друзі, колеги та керівники... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Люди, які розчиняють свої власні емоційні шаблони та страхи, і таким чином більше не відіграють їх на своїх сім\'ях, колегах та свійських тварин, роблять активний внесок у більш мирну світову спільноту.</li>' +
                '</ul>' +
                '<p>Детальну інформацію про походження та наукову основу емоційного дозволу див. на сайті: <a href="https://www.emotionalhealthinstitute.org/emotional-resolution">https://www.emotionalhealthinstitute.org/emotional-resolution</a></p>\n' +
                '<p>Для кращої читабельності ми обійшлися без виділення за статевою ознакою. Звичайно, ми завжди звертаємося до всіх жінок і чоловіків і просимо наших читачів поставитися до цього з розумінням.</p>'
        },
        helpCard: {
            title: 'Чи потрібна вам допомога?',
            content2: 'Якщо вам потрібна підтримка, тому що ви не хочете пробувати це самостійно або ваші проблеми здаються вам дуже глибокими, тут ви знайдете професійну підтримку.',
            content: 'Чи боїшся ти іспитів? Чи є у тебе екзистенційний страх? Ти боїшся темряви, собак чи павуків...?',
            link: '@:sidePanel.basicCourseCard.link',
            linkEres: 'професійну підтримку <i class="material-icons">open_in_new</i>',
            url: 'https://www.eres-academia.de/'
        }
    },
    welcome: {
        title: {
            value: 'Ти вже знав, …',
            value1: 'що ти можеш розчинити страх, стрес та заборони через своє тіло?'
        },
        content: {
            value: 'Твоє тіло також знає, як зробити це, якщо ви дозволите йому, тому що ця біологічна здатність є уродженою у нас, людей, і може принести тобі полегшення в емоційних ситуаціях.',
            value1: 'Твоє тіло також знає, як зробити це, якщо ви дозволите йому, тому що ця біологічна здатність є уродженою у нас, людей, і може принести тобі полегшення в емоційних ситуаціях...',
            value2: 'Ми дійсно можемо через наше тіло розчинити стрес, гнів та лють, а також страхи та дискомфорт безпосередньо у повсякденному житті. Саме так спочатку задумано природою. Але протягом довгого часу через нашу культуру ми більше не маємо прямого доступу до цієї природної спроможності. Сьогодні ми знаємо, як свідомо активувати його. І це абсолютно безкоштовно!'
        }
    },
    purpose: {
        title: 'Що ти отримуєш від цього?',
        content: '<span style="font-size: 1em; font-weight: bold">ВАШЕ ЖИТТЯ СТАНЕ ЛЕГШЕ!</span>'
    },
    purpose2: {
        title: '',
        content: '<ul class="disc">' +
            '<li>Стрес у вашому повсякденному житті зменшується, і ви стаєте більш розслабленим. Крім того, у вас буде менше стресу з іншими людьми, і ви житимете здоровішим життям. Тому що - це було науково доведено - стрес робить нас хворими у довгостроковій перспективі.</li>' +
            '<li>Зняття стресу допоможе вам, чи вчитеся ви у школі, коледжі чи університеті, чи працюєте ви, безробітний чи пенсіонер, чи дбаєте ви про дітей, домашнє господарство чи сім\'ю.</li>' +
            '</ul>'
    },
    howTo: {
        title: 'І як це працює?',
        content: 'Було розроблено спеціальну процедуру, щоб свідомо активувати нашу біологічну здатність розчиняти стрес, страхи та блокування. Він відомий як Емоційний дозвіл або ERES® та викладається безкоштовно Інститутом емоційного здоров\'я (IEG). Свідома активація цієї здатності за допомогою ERES® легко навчитися – і вам теж.'
    },
    course: {
        title: 'Процедура крок за кроком:',
        step1: {
            title: 'Крок 1: Підготовка',
            preface: 'Щоб підготуватися, вам потрібно переконатися, що ваше тіло дійсно реагує на страх і стрес.',
            content:
                '<p>Ви коли-небудь стикалися з тим, що ваше тіло реагує, наприклад, коли ви злитесь, чи ви спостерігали це в інших?</p>' +
                '<p>У разі сильного гніву чи люті багато людей відчувають, що їм стає, наприклад, спекотно. Крім того, може червоніти голова, частішати серцебиття та дихання, напружуватися м\'язи.</p>' +
                '<p>Якщо ви злитесь, сумуйте або відчуваєте будь-який інший стрес, спробуйте самі, де в тілі ви можете відчути, як воно реагує. Також зверніть увагу на будь-який дискомфорт, який лише трохи помітний, тому що як слабкі, так і сильні реакції свідчать про початок природного процесу Емоційного Дозвілу (ERES®).</p>' +
                '<p>Якщо ви спробували і можете підтвердити ці відчуття, то далі йде крок 2.</p>'
        },
        step2: {
            title: 'Крок 2: Виберіть досвід',
            preface: 'Якщо ви хочете спробувати, як ви можете розв\'язати неприємні емоції за допомогою ERES, вам потрібен певний досвід стресу з вашого повсякденного життя (гнів, страх, сум, блокування...).',
            content:
                '<p>Який із них ви вибираєте? Що вас турбує та призводить до стресу у вашому житті? Чого ви боїтесь? Де ви пригнічені?</p>' +
                '<p>Приклади:' +
                '<ul class="disc">' +
                '<li>Ви злитесь через те, що хтось щось сказав.</li>' +
                '<li>Ви зараз почуваєтеся пригніченим, безпорадним і в смутку.</li>' +
                '<li>Ви боїтеся майбутнього іспиту або будь-коли випробовували блекаут.</li>' +
                '<li>Ви сором\'язливі та боїтеся розмовляти з іншими.</li>' +
                '<li>Ви боїтеся висоти, польоту, павуків, собак чи у вас клаустрофобія...</li>' +
                '</ul>' +
                '</p>' +
                '<p>Як тільки ви опинитеся в ситуації у повсякденному житті, в якому ви відчуваєте щось подібне, спочатку свідомо відзначте, де ваше тіло реагує неприємно. Ця перша частина тіла, де виникає неприємне відчуття, зазвичай відчувається безпосередньо, наприклад, тиск у грудях, біль у шлунку або щось інше, що лише трохи незручно. Найкраще покласти руку туди, де ви це відчуваєте.</p>'
        },
        step3: {
            title: 'Крок 3: Внутрішнє сприйняття',
            preface: 'А тепер заплющте очі! Ви свідомо активуєте свою природну здатність розчиняти страх та стрес, просто спостерігаючи за тим, що відбувається у вашому тілі.',
            content:
                '<p><span class="bold">Тут важливе таке:</span>' +
                '<ul class="disc">' +
                '<li>Нічого не робіть, не рухайтеся! (Будьте пасивні та нерухомі, як статуя). Навчіться просто сприймати протягом усього процесу.</li>' +
                '<li>Повністю відпустіть ситуацію, яка раніше викликала у вас емоції! Перестаньте думати про це та просто зосередьтеся на відчуттях свого тіла.</li>' +
                '</ul>' +
                '</p>' +
                '<p class="bold">Інакше не вийде!</p>' +
                '<p>Іноді також необхідно відправитися в місце, де ви відчуваєте себе в достатній безпеці, щоб заплющити очі на кілька хвилин і просто спостерігати відчуття свого тіла.</p>'
        },
        step4: {
            title: 'Крок 4: Відчуйте своє тіло',
            preface: 'Продовжуйте відчувати лише у своєму тілі! Зверніть увагу, де ви можете відчути та назвати іншу неприємну реакцію тіла.',
            content: '<p>Наприклад, це може бути неприємне занепокоєння в будь-якому місці тіла, печіння чи поколювання. Серцебиття може частішати, дихання частішати або сповільнюватися. Біль, тиск, печіння, свербіж тощо можуть виникати у спині чи іншому місці. Кожне з цих тілесних відчуттів, які ви помічаєте другим, показує, що ваша природна здатність тіла дозволяє неприємним емоціям чи стресу розчинятися. Ви також можете покласти руку на це місце, щоб бути більш поінформованим про це.</p>' +
                '<p>Це друге неприємне тілесне відчуття, а за ним та інші, присутні у кожній стресовій емоції, яку ми відчуваємо у повсякденному житті. Трохи потренувавшись, ми сприймаємо їх дуже швидко, з того моменту, як заплющимо очі.</p>' +
                '<p>Як тільки ви спробуєте це кілька разів із різними емоціями та стресами, ви зрозумієте, що наше тіло завжди реагує на кожну емоцію.</p>'
        },
        step5: {
            title: 'Крок 5: Слідуйте відчуттям свого тіла',
            preface: 'Стежте уважно за подальшим розвитком у своєму тілі. Нічого не робіть! Навчіться по-справжньому спостерігати пасивно! Нехай усі відчуття тіла змінюються самі собою, доки не заспокояться!',
            content: '<p>Вони можуть виникати послідовно чи одночасно. Нормальний процес полягає в тому, що якщо ви не рухаєтеся або не проявляєте активності, вони розвиваються власними силами! Це не займе багато часу. Через дві-три хвилини, а часто і швидше, ваше тіло знову почуватиметься добре, і всі реакції організму заспокояться.</p>' +
                '<p>Для цього кроку корисно знати:' +
                '<ul class="disc">' +
                '<li>Кожен стрес, кожен страх, кожне "я не можу цього зробити" пов\'язане зі своїм специфічним набором певних тілесних відчуттів. Ви можете думати про це як про фільм, який відтворюється знову і знову щоразу, коли ви відчуваєте певну емоцію. Якщо ви застосовуєте ЕРЕС із різними емоціями, ви це помітите.</li>' +
                '<li>Більшість тілесних відчуттів, що виникають, зазвичай неприємні тільки протягом короткого часу і дуже швидко змінюються. Це також відноситься до бурхливих фізичних реакцій, які іноді трапляються.</li>' +
                '</ul></p>'
        },
        step6: {
            title: 'Крок 6:  Коли все заспокоїться',
            preface: 'Розплющте очі, як тільки вщухнуть неприємні тілесні відчуття.',
            content: ' Витратьте кілька секунд, щоб повністю повернутися назовні. Знову усвідомте своє оточення і те, де ви на даний момент. Як ви себе почуваєте?'
        },
        step7: {
            title: 'Крок 7: Все зникло?',
            preface: 'Зробіть ще одну перевірку, щоб переконатися, що все зникло: деякі емоційні проблеми потребують двох проходів.',
            content: '<p>Запитайте себе, згадуючи про те, що вас напружувало: «Що мене лякало, де я був заблокований або в розпачі, що я відчуваю з цього приводу зараз?»“</p>' +
                '<p>Вам лишень краще? Емоції стали слабшими, але не зникли?</p>' +
                '<p>У цьому випадку зробіть ще один такий самий прохід. Запитайте себе ще раз: "Де я відчуваю щось некомфортне у своєму тілі?" і повторіть кроки 2-5 так само, як вперше.</p>' +
                '<p>Якщо все гаразд, і ви почуваєтеся емоційно врівноваженим після першої пробіжки, ви успішно застосували свою природну здатність до регулювання. Вітаємо!</p>' +
                '<p>Тоді ви в повсякденному житті помітите, чи насправді вирішилося все, що було пов\'язано з початковим стресом, як тільки ви спокійно відреагуєте в подібній ситуації.</p>' +
                '<p>Можливо зараз з\'являються інші глибинні емоції, які також незручні для вас. Щоб не склалося враження, що ЕРЕС у вас не спрацював, важливо назвати емоції, з якими ви робите ЕРЕС. Так вам буде легше відрізнити їх від інших емоцій, які ви відчуваєте у подібних стресових ситуаціях. Також корисно записувати їх.</p>' +
                '<p>Кожна додаткова неприємна емоція, яку ми відчуваємо, потребує застосування ERES. Як тільки ви усвідомлюєте ефективність ERES, можна використовувати його якомога частіше. Чим частіше ви це робитимете, тим спокійніше, безбоязніше вільніше і сміливіше почуватиметеся!</p>'
        },
        tips: {
            title: 'Поради - якщо у вас не вийшло з першого разу',
            1: {
                title: 'Ви відчуваєте дуже сильні, неприємні тілесні відчуттяc',
                content: '<p>Іноді дуже сильні емоції викликають це навіть на короткий час. Це можуть бути: сильне поколювання, тремтіння, задишка, запаморочення або нудота...</p>' +
                    '<p>Наша спонтанна реакція на це часто полягає в тому, що ми концентруємось на цих неприємних тілесних почуттях або розплющуємо очі. Шкода, бо це перериває процес. Щоб цього не сталося з вами, вам важливо <span class="bold">лише пасивно спостерігати</span>. Для цього потрібно знати, що навіть сильні неприємні відчуття змінюються та заспокоюються протягом кількох секунд, якщо просто дати їм змінитись.</p>'
            },
            2: {
                title: 'Що мається на увазі під «пасивним наглядом»?',
                content: 'Протягом усього цього посібника з ERES® вам постійно говорять залишатися пасивними і дійсно нічого не робити, доки ваші очі заплющені. Суть у тому, щоб жодним чином не впливати на перебіг тілесних відчуттів: через активне дихання, через обмірковування вихідної ситуації або інших подібних ситуацій. Будь-який рух також є формою становлення активним. У більшості випадків ми робимо це несвідомо, щоб пом\'якшити чи уникнути неприємних відчуттів. У цьому немає необхідності, тому що в пасивному режимі все одно все швидко змінюється і заспокоюється само собою. Будь-який вплив уповільнює або запобігає природному процесу ERES®.'
            },
            3: {
                title: 'Свідоме сприйняття зовнішнього шуму.',
                content: '<p>Для багатьох із нас найпростіший спосіб використовувати ERES® – це залишити частину своєї уваги зовні. <span class="bold">Для цього звертайте увагу на навколишні звуки.</span> Сильні фізичні відчуття, що іноді виникають, змінюються за допомогою цього швидше.</p><p>' +
                    'Чому це відбувається? Якщо ми одночасно із заплющеними очима сприймаємо те, що відбувається всередині нашого тіла і в нашому оточенні, то велика ймовірність того, що емоційні тілесні реакції, що зберігаються в нашій тілесній пам\'яті, як у фільмі, можуть безперешкодно слідувати одна за одною, змінюватись і заспокоюватися.' +
                    '</p>'
            },
            4: {
                title: 'Ви весь час зберігаєте контроль над тим, що відбувається',
                content: '<p>якщо фізичні відчуття, що виникають, занадто неприємні для вас, або якщо ви почуваєтеся не зовсім комфортно там, де знаходитесь із заплющеними очима, ви можете в будь-який момент знову відкрити очі і таким чином закінчити процес.</p>' +
                    '<p>Як тільки емоція, яку ви хотіли дозволити за допомогою ERES®, знову з\'явиться у вашому повсякденному житті, ви можете знову спробувати вирішити її безпосередньо.</p>'
            }
        },
        next: {
            title: 'Для того, щоб ви ще більше познайомилися з ERES®:',
            content: '<p>Варто записати кожен досвід, який ви зазнали при використанні Емоційного Дозвілу (ERES®). Досить відзначити перші три тілесні відчуття відповідної емоції. Ви виявите, що кожна емоція має свій власний підпис та послідовність і зберігається разом з нею, як у бібліотеці у пам\'яті нашого тіла.</p>' +
                '<p>Наприклад, створіть для нього записник або диктуйте свої враження на смартфон.</p>' +
                '<p>І розповідай далі. Природа влаштована так, що якщо ми не записували переживання або не ділилися ними з іншими, то часто геть-чисто забуваємо про страхи, стреси і комплекси, що постійно вирішилися. Ми ще краще пам\'ятаємо корисність цієї здібності, коли розповідаємо іншим, як нам вдалося впоратися з конкретним страхом чи стресом.</p>'
        },
        limits: {
            title: 'Обмеження ERES® та особиста відповідальність:',
            content: '<p>IEG вважає, що знання того, як використовувати нашу природну здатність самостійно справлятися з неприємними емоціями, є особливим шансом. У той самий час важливо взяти він особисту відповідальність вивчення процесу. Якщо вам потрібна підтримка, тому що ви не хочете пробувати це самостійно або ваші проблеми здаються вам дуже глибокими, тут ви знайдете професійну підтримку:<a href="https://www.emotionalhealthinstitute.org/emotional-resolution">https://www.emotionalhealthinstitute.org/emotional-resolution</a></p>'
        },
        shortVersion: {
            title: 'Коротка інструкція щодо використання ERES',
            content: '<ul class="disc"><li>Звертайте увагу на специфічні стресові ситуації у вашому повсякденному житті (гнів, страх, сум, блокування...), щоб використовувати ERES®!</li>' +
                '<li>Як тільки ви зазнаєте неприємної емоції, пам\'ятайте, що з нею можна використовувати ERES® і ви знаходитесь у безпечному місці, запитайте себе: «Де я зараз це відчуваю у своєму тілі?»</li>' +
                '<li>Якщо хочете, помістіть руку туди, де ви відчуваєте неприємні тілесні відчуття. Це полегшує пасивне відчуття у тілі.</li>' +
                '<li>Тепер заплющити очі!</li>' +
                '<li>Де ви помічаєте друге неприємне тілесне відчуття? Якщо хочеш, можеш покласти туди та руку.</li>' +
                '<li>Нічого не робити!</li>' +
                '<li>Спостерігайте за тим, що відбувається у вашому тілі, і як нові тілесні відчуття з\'являються одне за одним і змінюються знову і знову! Ви також можете стежити за цими змінами руками.</li>' +
                '<li>Робіть це доти, доки не заспокояться всі тілесні відчуття! Це займає близько двох-трьох хвилин; іноді швидше.</li>' +
                '<li>Коли неприємних тілесних відчуттів більше не буде, розплющте очі!</li>' +
                '<li>Поверніться зовні і тепер запитайте себе: «Якщо я знову подумаю про те, що мене щойно напружило, що я відчуваю з цього приводу зараз?»</li>' +
                '<li>Якщо ви почуваєтеся «лише краще», але емоція ще повністю вирішилася, , зробіть другий ідентичний прохід! Це також починається із запитання: «Де я відчуваю щось некомфортне у своєму тілі?»</li>' +
                '<li>Якщо після першого раунду все гаразд, і ви почуваєтеся емоційно врівноваженим, ви успішно використовували ERES®. У подібних повсякденних ситуаціях ви отримаєте підтвердження: цього разу ви залишалися спокійними.</li></ul>'
        }
    },
    disclaimer: {
        title: 'Відмова від відповідальності',
        content: '<p>ERES – це ефективна форма першої допомоги при стресі та інших неприємних емоціях, що виникають у повсякденному житті. Водночас він не замінює жодної форми терапії чи візиту до лікаря. Застосування описаної процедури знаходиться під особистою відповідальністю користувача.</p>' +
            '<p>Вся інформація надається без гарантій з боку авторів або IEG. Тому будь-яка відповідальність IEG або її агентів за травми, матеріальні збитки або фінансові втрати виключається.</p>',
        contentShort: '<p>ERES – це ефективна форма першої допомоги при стресі та інших неприємних емоціях, що виникають у повсякденному житті. Водночас він не замінює жодної форми терапії чи візиту до лікаря. Застосування описаної процедури знаходиться під особистою відповідальністю користувача.</p>'
    },
    support: {
        title: 'Я хотів би підтримати IEG',
        content: '<p>За допомогою цього веб-сайту ми хотіли б зробити інструкції щодо ERES доступними для кожного на його рідній мові, а також поширювати знання про цей ресурс серед інших ініціатив. Як неприбуткова асоціація, ми покладаємося на пожертви, щоб зробити це можливим. Ми будемо раді, якщо ви підтримаєте нас, перерахувавши пожертвування на такий рахунок:</p>' +
            'одержувач: Institut für Emotionale Gesundheit e. V.<br>' +
            'IBAN : DE13 4786 0125 0530 8813 00<br>' +
            'BIC : GENODEM1GTL<br>' +
            '<p>Якщо ви хочете отримати квитанцію про пожертвування, будь ласка, не забудьте вказати своє ім’я та повну адресу під час переказу, щоб ми могли надіслати вам його.</p>'
    }
}