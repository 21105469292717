export default {
    header: {
        languagePicker: {
            title: 'Sprache wählen',
            button: 'übernehmen'
        },
        shortVersion: {
            link: 'Kurzanleitung ERES®'
        },
        longVersion: {
            link: 'Anleitung ERES®'
        }
    },
    footer: {
        privacy: {
            cookie: 'Wir benutzen keine Cookies.',
            title: 'Datenschutzerklärung'
        },
        partner: {
            title: 'Unsere Partnerinstitute'
        },
        images: {
            title: 'Verwendete Bilder'
        }
    },
    sidePanel: {
        basicCourseCard: {
            title: 'Leitfaden',
            content: 'Sieh dir unseren Leitfaden an um schnell einen Überblick zu bekommen.',
            link: 'Kurzanleitung lesen'
        },
        aboutUs: {
            title: 'Über Uns',
            content: 'Das Institut für Emotionale Gesundheit möchte allen Menschen weltweit das Wissen zur Verfügung stellen, dass wir die biologische Fähigkeit haben, die vielen Formen von Angst, Stress, Ärger und Blockaden, die wir erleben, über den Körper aufzulösen. Es ist seinen Mitgliedern ein wichtiges Anliegen, auf dieser Homepage eine genaue Anleitung zur Anwendung dieser Ressource an sich selbst im Alltag zur Verfügung zu stellen. In Deutschland, Österreich und der deutschsprachigen Schweiz wird die bewusste Vorgehensweise der Anwendung der biologischen Ressource Emotional Resolution, kurz ERES® genannt.',
            link: 'Mehr erfahren',
            tltr: '<p>Das Institut für Emotionale Gesundheit (IEG) ist ein gemeinnütziger Verein, dessen zertifizierte Ausbilder und Coachs in langjähriger Zusammenarbeit mit französischen Forschern, Physiotherapeuten und Ärzten die Kenntnisse über ERES® nach Deutschland gebracht haben. In Frankreich wurde die natürliche Ressource von ERES® in den 80er Jahren entdeckt und seitdem intensiv erforscht und weiterentwickelt.</p>\n' +
                '<p>Zielsetzung des Vereins ist die Förderung der emotionalen Gesundheit von Menschen und Tieren weltweit. Durch die Nutzung der biologischen Ressource der emotionalen Selbstregulierung, die allen Lebewesen zur Verfügung steht, können Menschen nach und nach ihre alten, störenden emotionalen Muster auflösen. Dadurch übertragen sie ihren eigenen Stress und ihre Ängste nicht mehr auf ihre Mitmenschen und Tiere. Zunehmende Gelassenheit und Lebensfreude ermöglichen so ein friedlicheres Miteinander von Menschen untereinander und von Mensch und Tier.</p>\n' +
                '<p>Der wissenschaftliche und soziale Fokus des IEGs liegt darauf, zu verstehen und weiterzugeben, wie unser Gehirn emotionale Muster erschafft und aufrechterhält und wie sie dauerhaft durch die Nutzung von ERES aufzulösen sind. Dazu folgende Ideen:</p>\n' +
                '<ul style="margin-left: 2em; list-style-position: inherit">' +
                '<li style="list-style-type: disc;font-weight: bold">Ein emotional gelassenes und zufriedenes Kind lernt leichter in der Schule, kommt besser mit seinen Geschwistern und Freunden klar und fühlt sich dadurch fröhlicher und unbeschwerter... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Emotional ausgeglichene Jugendliche brauchen weniger Drogen und Medikamente, kommen besser mit ihren Eltern und ihrer Ausbildung klar und finden dadurch leichter ihren Weg in eine autonome Zukunft... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Emotional gelassene Erwachsene sind ausgeglichenere Eltern, Nachbarn, Freunde und Kollegen und Führungspersonen... </li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Menschen, die ihre eigenen emotionalen Muster und Ängste auflösen, und so diese nicht mehr bei ihren Familien, Kollegen und Haustieren ausleben, sind aktive Mitstreiter und Mitstreiterinnen für eine friedlichere Weltengemeinschaft. </li>' +
                '</ul>' +
                '<p>Für detaillierte Informationen zur Herkunft und wissenschaftlichen Grundlage der Emotional Resolution siehe: <a href="https://www.emotionalhealthinstitute.org/emotional-resolution">https://www.emotionalhealthinstitute.org/emotional-resolution</a></p>\n' +
                '<p>Aus Gründen der besseren Lesbarkeit haben wir auf das Gendern verzichtet. Selbstverständlich beziehen wir uns immer auf alle Frauen und Männer und bitten unsere Leserinnen und Leser um Verständnis.</p>'
        },
        helpCard: {
            title: 'Brauchst du Hilfe?',
            content2: 'Wenn du es nicht alleine ausprobieren willst oder deine Probleme dir sehr tiefgreifend erscheinen, findest du hier professionelle Unterstützung.',
            content: 'Hast du Prüfungsangst? Hast du Existenzangst? Fürchtest du dich vor Dunkelheit, Hunden oder Spinnen...?',
            link: '@:sidePanel.basicCourseCard.link',
            linkEres: 'Professionelle Hilfe <i class="material-icons">open_in_new</i>',
            url: 'https://www.eres-academia.de/'
        }
    },
    welcome: {
        title: {
            value: 'Wusstest du schon,...',
            value1: 'dass du Angst, Stress und Hemmungen über deinen Körper auflösen kannst?'
        },
        content: {
            value: 'Dein Körper weiß auch wie das geht, wenn du ihn lässt. Denn diese biologische Fähigkeit ist uns Menschen angeboren und kann dir in emotionalen Notsituationen Erleichterung bringen.',
            value1: 'Das hilft dir, wenn du dich ohnmächtig oder verzweifelt fühlst, weil du nicht weißt, wie es weitergehen soll. Oder wenn du Angst hast, dich ärgerst oder gelähmt fühlst...',
            value2: 'Wir können tatsächlich Stress, Ärger und Wut sowie Ängste und Unbehagen über unseren Körper direkt im Alltag auflösen. So hat es die Natur ursprünglich für uns vorgesehen. Doch haben wir Menschen seit langem kulturbedingt keinen unmittelbaren Zugang mehr zu dieser natürlichen Fähigkeit. Heute wissen wir, wie wir sie bewusst aktivieren können. Und es ist vollkommen kostenlos!'
        }
    },
    purpose: {
        title: 'Was hast du davon?',
        content: '<span style="font-size: 1em; font-weight: bold">DEIN LEBEN WIRD LEICHTER!</span>'
    },
    purpose2: {
        title: '',
        content: '<ul class="disc">' +
            '<li>Der Stress in deinem Alltag wird kleiner und du wirst gelassener. Auch mit deinen Mitmenschen wird es stressfreier und du lebst gesünder. Denn – das ist wissenschaftlich belegt – Stress macht uns auf Dauer krank.</li>' +
            '<li>Der Stressabbau wird dir helfen, ganz gleich, ob du in der Schule, der Ausbildung oder im Studium bist, ob du berufstätig, arbeitslos oder in Rente bist, oder ob du dich um Kinder, Haushalt oder um deine Familie kümmerst.</li>' +
            '</ul>'
    },
    howTo: {
        title: 'Und wie geht das?',
        content: 'Es wurde eine besondere Vorgehensweise entwickelt, um unsere biologische Fähigkeit, Stress, Ängste und Blockaden aufzulösen, bewusst zu aktivieren. Sie ist als Emotional Resolution bzw. ERES® bekannt und wird vom Institut für Emotionale Gesundheit (IEG) kostenlos vermittelt. Die bewusste Aktivierung dieser Fähigkeit durch ERES® ist leicht erlernbar – auch für Dich.'
    },
    course: {
        title: 'Die Vorgehensweise Schritt für Schritt:',
        step1: {
            title: 'Schritt 1: Vorbereitung',
            preface: 'Zur Vorbereitung brauchst du die Erfahrung, dass dein Körper bei Angst und Stress tatsächlich reagiert.',
            content:
                '<p>Hast du schon einmal erlebt, dass dein Körper reagiert, wenn du zum Beispiel wütend bist - oder hast du es bei anderen beobachtet?</p>' +
                '<p>Bei großem Ärger oder Wut spüren viele Menschen, dass ihnen z.B. heiß wird. Außerdem kann u.a. der Kopf rot werden, das Herz und die Atmung werden schneller, Muskeln spannen sich an.</p>' +
                '<p>Wenn du dich ärgerst, traurig bist oder sonst irgendwie gestresst, probiere selbst aus, wo im Körper du wahrnehmen kannst, wie er reagiert. Achte auch auf nur leicht spürbare unangenehme Körperreaktionen, denn sowohl schwache als auch starke Reaktionen zeigen den Beginn des natürlichen Ablaufs der Emotional Resolution (ERES®). </p>' +
                '<p>Wenn du es ausprobiert hast und diese Erfahrung so bestätigen kannst, folgt Schritt zwei.</p>'
        },
        step2: {
            title: 'Schritt 2: Wähle eine Erfahrung',
            preface: 'Willst du ausprobieren, wie du mit ERES unangenehme Emotionen auflösen kannst, brauchst du dazu eine konkrete Stresserfahrung aus deinem Alltag (Ärger, Angst, Traurigkeit, Blockaden…).',
            content:
                '<p>Welche wählst du aus? Was stört und stresst dich in deinem Leben? Wovor hast du Angst? Wo bist du gehemmt?</p>' +
                '<p>Beispiele:' +
                '<ul class="disc">' +
                '<li>Du ärgerst dich über etwas, was jemand gesagt hat.</li>' +
                '<li>Du fühlst dich gerade niedergeschlagen, hilflos und mutlos.</li>' +
                '<li>Du hast Angst vor einer anstehenden Prüfung oder schon einmal einen Blackout erlebt.</li>' +
                '<li>Du bist schüchtern und hast Angst andere anzusprechen.</li>' +
                '<li>Du kennst Höhenangst, Flugangst, Angst vor Spinnen, Angst vor Hunden, Platzangst…</li>' +
                '</ul>' +
                '</p>' +
                '<p>Sobald du im Alltag in eine Situation kommst, in der du etwas Derartiges erlebst, nimm deine Emotion bewusst wahr und benenne sie. Nun ist es besonders wichtig, dass du die Emotion nicht loswerden willst, sondern sie annimmst und freundlich willkommen heißt – ganz gleich wie stark oder unangenehm sie ist. Nimm jetzt wahr, wo die Emotion sich in deinem Körper zeigt. Die erste Körperstelle, wo es gerade unangenehm ist, spüren wir meistens direkt: z.B. einen Druck auf der Brust, einen Schmerz im Bauch oder etwas Anderes auch nur leicht Unangenehmes. Am besten legst du die Hand dort hin, wo du es wahrnimmst.</p>'
        },
        step3: {
            title: 'Schritt 3: Innere Wahrnehmung',
            preface: 'Jetzt schließe die Augen! Aktiviere bewusst deine natürliche Fähigkeit, Angst und Stress aufzulösen, indem du ausschließlich beobachtest, was sich gerade in deinem Körper zeigt.',
            content:
                '<p><span class="bold">Wichtig dabei ist:</span>' +
                '<ul class="disc">' +
                '<li>Tue nichts, bewege dich nicht! (Sei so passiv und unbeweglich wie eine Statue). Lerne, einfach nur wahrzunehmen während des gesamten Ablaufs und jede auftretende Körperempfindung freundlich anzunehmen und willkommen zu heißen.</li>' +
                '<li>Lass die Situation, die zuvor deine Emotion ausgelöst hatte, vollkommen los! Denk nicht mehr daran und bleibe mit deiner Aufmerksamkeit ausschließlich bei deinen Körperempfindungen.</li>' +
                '</ul>' +
                '</p>' +
                '<p class="bold">Sonst funktioniert es nicht!</p>' +
                '<p>Manchmal ist es auch notwendig, einen Ort aufzusuchen, an dem du dich sicher genug fühlst, um in Ruhe für wenige Minuten die Augen zu schließen und deine Körperempfindungen einfach nur wahrzunehmen.</p>'
        },
        step4: {
            title: 'Schritt 4: Spüre deinen Körper',
            preface: 'Spüre weiterhin ausschließlich in deinen Körper hinein! Nimm wahr, wo du noch eine weitere unangenehme Körperreaktion spüren und benennen kannst.',
            content: '<p>Es kann beispielsweise ein unangenehmes Kribbeln an irgendeiner Stelle deines Körpers sein, ein Brennen oder Stechen. Der Herzschlag kann schneller werden, die Atmung schneller oder langsamer. Ein Schmerz, Druck, Brennen, Jucken oder ähnliches kann im Rücken oder woanders auftreten. Jede dieser Körperempfindungen, die du als Zweite feststellst, zeigt dir, dass deine natürliche Kompetenz, eine unangenehme Emotion oder Stress sich auflösen zu lassen, gerade aktiviert ist. Du kannst, um es bewusster wahrzunehmen, auch an diese Stelle eine Hand legen.</p>' +
                '<p>Diese zweite unangenehme Körperempfindung und danach auch weitere, die darauf folgen, gibt es bei jeder stressigen Emotion, die wir im Alltag erleben. Mit ein wenig Übung nehmen wir sie sehr schnell wahr, ab dem Moment, in dem wir die Augen geschlossen haben.</p>' +
                '<p>Sobald du das mehrfach ausprobiert hast mit unterschiedlichen Emotionen und Stress, wird dir klar sein, dass unser Körper tatsächlich immer auf jede Emotion reagiert.</p>'
        },
        step5: {
            title: 'Schritt 5: Folge deinen Körperempfindungen',
            preface: 'Folge mit deiner Aufmerksamkeit der weiteren Entwicklung in deinem Körper. Tue nichts! Lerne, wirklich vollkommen passiv zu beobachten! Lasse alle Körperempfindungen sich ganz von alleine verändern, bis sie sich beruhigt haben!',
            content: '<p>Sie können nacheinander oder gleichzeitig auftreten. Der normale Ablauf ist, dass sie sich – wenn du dich nicht bewegst oder sonst irgendwie aktiv wirst - alleine weiterentwickeln! Es dauert nicht lange. Nach zwei bis drei Minuten – oft auch schneller – wird sich dein Körper wieder gut anfühlen, und alle Körperreaktionen haben sich beruhigt.</p>' +
                '<p>Für diesen Schritt ist es hilfreich zu wissen:' +
                '<ul class="disc">' +
                '<li>Jeder Stress, jede Angst, jedes „Ich kann das nicht“ ist mit einer ganz eigenen spezifischen Ansammlung von bestimmten Körperempfindungen verbunden. Du kannst es dir vorstellen wie einen immer gleichen Film, der jedes Mal, wenn du eine bestimmte Emotion erlebst, wiederabgespielt wird. Wenn du mit unterschiedlichen Emotionen ERES anwendest, wirst du dies feststellen.</li>' +
                '<li>Die meisten der auftretenden Körperempfindungen sind in der Regel nur kurz unangenehm und verändern sich sehr schnell, wenn du sie freundlich annimmst und in dir willkommen heißt. Dies gilt auch für die manchmal auftauchenden heftigen Körperreaktionen.</li>' +
                '</ul></p>'
        },
        step6: {
            title: 'Schritt 6: Wenn sich alles beruhigt hat',
            preface: 'Öffne die Augen, sobald sich alle unangenehmen Körperempfindungen beruhigt haben.',
            content: ' Lass dir ein paar Sekunden Zeit, um wieder ganz im Außen anzukommen. Nimm deine Umgebung wieder bewusst wahr und wo du gerade bist. Wie fühlst du dich?'
        },
        step7: {
            title: 'Schritt 7: Ist alles aufgelöst?',
            preface: 'Mache noch eine Überprüfung, ob alles aufgelöst ist: Manche emotionalen Probleme brauchen zwei Durchgänge.',
            content: '<p>Stelle dir die Frage: „Wenn ich nochmal an das denke, was mich eben gestresst oder geängstigt hat, wo ich blockiert oder verzweifelt war – wie geht es mir jetzt damit?“</p>' +
                '<p>Geht es dir nur „besser“? Ist die Emotion zwar schwächer, aber nicht verschwunden?</p>' +
                '<p>In diesem Fall mache einen weiteren, genau gleichen Durchgang. Frage dich erneut: „Wo zeigt sich meine Emotion noch in meinem Körper?“ und wiederhole die Schritte 2-5 genau wie beim ersten Mal.</p>' +
                '<p>Ist alles gut und fühlst du dich schon nach dem ersten Durchlauf emotional ausgeglichen, hast du deine natürliche Fähigkeit zur Regulierung erfolgreich angewendet. Gratuliere!</p>' +
                '<p>Ob tatsächlich alles, was mit dem ursprünglichen Stress zusammen hing, aufgelöst ist, bemerkst du dann im Alltag, sobald du in einer ähnlichen Situation gelassen reagierst.</p>' +
                '<p>Es kann sein, dass nun andere, darunter liegende Emotionen auftauchen, die dir ebenfalls unangenehm sind. Damit du nicht den Eindruck bekommst, dass ERES bei dir nicht funktioniert hat, ist es wichtig, die Emotionen, mit denen du ERES machst, genau zu benennen. So kannst du sie leichter von anderen Emotionen unterscheiden, die du in ähnlichen stressigen Situationen erlebst. Hilfreich ist es auch, sie aufzuschreiben.</p>' +
                '<p>Jede weitere unangenehme Emotion, die wir erleben, braucht eine eigene Anwendung von ERES. Sobald du die Wirksamkeit von ERES erkannt hast, ist es sinnvoll, es so häufig wie möglich anzuwenden. Je öfter du es machst, desto gelassener, furchtloser, innerlich freier und mutiger wirst du dich fühlen!</p>'
        },
        tips: {
            title: 'Tipps – wenn es nicht gleich beim ersten Mal erfolgreich war',
            1: {
                title: 'Du erlebst sehr heftige unangenehme Körperempfindungen',
                content: '<p>Manchmal tauchen bei sehr heftigen Emotionen auch kurzfristig sehr heftige Körperempfindungen auf. Diese können sein: ein starkes Stechen, Zittern, Atemnot, Schwindel oder Übelkeit…</p>' +
                    '<p>Unsere spontane Reaktion darauf ist häufig, dass wir uns auf diese unangenehmen Körperreaktionen konzentrieren oder die Augen aufmachen. Das ist schade, denn dadurch unterbrechen wir den Vorgang. Damit dir das nicht passiert, ist es für dich wichtig, auch in diesem Fall <span class="bold">nur passiv zu beobachten</span>. Dazu brauchst du das Wissen, dass auch heftige unangenehme Empfindungen sich innerhalb weniger Sekunden verändern und beruhigen, wenn du sie sich einfach verändern läßt.\n</p>'
            },
            2: {
                title: 'Was ist gemeint mit „passiv beobachten“? ',
                content: 'In diesem Leitfaden zu ERES® wirst du wiederholt darauf hingewiesen, passiv zu bleiben und wirklich nichts zu tun, während du die Augen geschlossen hast. Es geht dabei darum, den Verlauf der Körperempfindungen in keiner Weise zu beeinflussen: weder durch aktives Atmen, noch durch Denken an die Ausgangssituation oder andere ähnliche Situationen. Auch jegliches Bewegen ist eine Form, aktiv zu werden. Meistens machen wir das unbewusst, um das Spüren von unangenehmen Empfindungen abzuschwächen oder zu vermeiden. Das ist nicht nötig, da alles sich im passiven Modus sowieso sehr schnell verändert und sich von selbst beruhigt. Jede Form der Beeinflussung verlangsamt oder verhindert den natürlichen Ablauf von ERES®.'
            },
            3: {
                title: 'Die Außengeräusche bewusst wahrnehmen',
                content: '<p>Die Anwendung von ERES® geht für viele von uns am einfachsten, wenn wir einen Teil unserer Aufmerksamkeit im Außen lassen. <span class="bold>"Dazu nimm bewusst auch die Geräusche in deiner Umgebung wahr.</span> Auch die manchmal auftretenden heftigen Körperempfindungen verändern sich dadurch schneller. </p><p>' +
                    'Woran liegt das? Wenn wir mit geschlossenen Augen gleichzeitig wahrnehmen, was im Inneren unseres Körpers UND in unserer Umgebung geschieht, ist die Wahrscheinlichkeit am größten, dass die in unserem Körpergedächtnis wie in einem Film abgespeicherten emotionalen Körperreaktionen ungestört aufeinander folgen, sich verändern und beruhigen können.' +
                    '</p>'
            },
            4: {
                title: 'Du behältst jederzeit die Kontrolle über das Geschehen',
                content: '<p>Wenn auftretende Körperempfindungen dir zu unangenehm sind, oder du dich mit geschlossenen Augen nicht vollkommen wohlfühlst an dem Ort, wo du bist, kannst du jederzeit die Augen wieder öffnen und damit den Ablauf beenden.</p>' +
                    '<p>Sobald die Emotion, die du mit ERES® auflösen wolltest, erneut in deinem Alltag auftritt, ist ein neuer Versuch, sie direkt aufzulösen möglich.</p>'
            }
        },
        next: {
            title: 'Um dich mit ERES® noch vertrauter zu machen:',
            content: '<p>Es lohnt sich, jede Erfahrung, die du mit der Anwendung der Emotional Resolution (ERES®) machst, aufzuschreiben. Dazu reicht es, die drei zuerst aufgetretenen Körperempfindungen der jeweiligen Emotion zu notieren. Du wirst so feststellen, dass jede Emotion eine eigene Signatur und Sequenz hat und mit dieser wie in einer Bibliothek in unserem Körpergedächtnis abgespeichert ist.</p>' +
                '<p>Lege z.B. ein Notizheft dafür an oder diktiere deine Erfahrungen in dein Smartphone.\n</p>' +
                '<p>Und erzähle es weiter. Die Natur hat es so eingerichtet, dass wir dauerhaft aufgelöste Ängste, Stress und Hemmungen häufig vollkommen vergessen, wenn wir die Erfahrungen nicht aufgeschrieben haben oder mit anderen geteilt haben. Noch besser prägt sich uns die Nützlichkeit dieser Fähigkeit ein, wenn wir anderen erzählen, wie wir es gemacht haben, eine bestimmte Angst oder Stress aufzulösen.</p>'
        },
        limits: {
            title: 'Grenzen von ERES® und eigene Verantwortung',
            content: '<p>Das Wissen um die Anwendung unserer natürlichen Fähigkeit, unangenehme Emotionen selbst auflösen zu können, stellt aus Sicht des IEG eine besondere Chance dar. Gleichzeitig ist es wichtig, für das Erlernen des Vorgangs in die Eigenverantwortung zu gehen.</p>'
        },
        shortVersion: {
            title: 'Kurzanleitung zur Anwendung von ERES®',
            content: '<ul class="disc"><li>Achte auf konkrete Stresserfahrungen in deinem Alltag (Ärger, Angst, Traurigkeit, Blockaden…), um ERES® anzuwenden!</li>' +
                '<li>Sobald du eine unangenehme Emotion erlebst, dich daran erinnerst, dass du damit ERES® anwenden kannst und dich an einem sicheren Ort befindest, benenne die Emotion und heiße sie willkommen. Dann stelle dir die Frage: „Wo spüre ich das gerade in meinem Körper?“  Heiße ebenso alle anderen Körperempfindungen, die auftauchen willkommen.</li>' +
                '<li>Lege, wenn du möchtest, eine Hand an die Stelle, wo du eine unangenehme Körperempfindung wahrnimmst. Dies erleichtert das passive Spüren in den Körper.</li>' +
                '<li>Schließe nun die Augen! </li>' +
                '<li>Wo stellst du eine zweite unangenehme Körperempfindung fest? Lege, wenn du möchtest, auch an diese Stelle eine Hand.</li>' +
                '<li>Tue nichts!</li>' +
                '<li>Beobachte freundlich, was in deinem Körper geschieht und wie nacheinander neue Körperempfindungen auftauchen und sich immer wieder verändern! Du kannst diesen Veränderungen auch mit deinen Händen folgen.</li>' +
                '<li>Tue dies, bis sich alle Körperempfindungen beruhigt haben! Das dauert ungefähr zwei bis drei Minuten; manchmal geht es auch schneller.</li>' +
                '<li>Wenn es keine unangenehmen Körperempfindungen mehr gibt, öffne die Augen!</li>' +
                '<li>Komm wieder im Außen an und frage dich nun: „Wenn ich erneut an das denke, was mich eben gestresst hat – wie geht es mir jetzt damit?“</li>' +
                '<li>Wenn es dir „nur besser“ geht, die Emotion aber noch nicht vollkommen aufgelöst ist, mache einen zweiten identischen Durchgang! Heiße die Emotion wieder willkommen und stelle dir die Frage: „Wo spüre ich sie in meinem Körper?“</li>' +
                '<li>Ist alles schon nach dem ersten Durchgang gut, und fühlst du dich emotional ausgeglichen, hast du ERES® erfolgreich angewendet. In ähnlichen Alltagssituationen wirst du die Bestätigung erfahren: Du bist dieses Mal gelassen geblieben</li></ul>'
        }
    },
    disclaimer: {
        title: 'Haftungsausschluss',
        content: '<p>ERES ist eine effektive Form der ersten Hilfe beim Auftreten von Stress und anderen unangenehmen Emotionen im Alltag. Gleichzeitig ersetzt es keine Form der Therapie und keinen Arztbesuch. Die Anwendung der hier beschriebenen Vorgehensweise erfolgt auf eigene Verantwortung.</p>' +
            '<p>Alle Angaben erfolgen ohne Gewährleistung oder Garantie seitens der Autoren bzw. des IEGs. Eine Haftung des IEGs bzw. seiner Beauftragten für Personen-, Sach- und Vermögensschäden ist daher ausgeschlossen.</p>',
        contentShort: '<p>ERES ist eine effektive Form der ersten Hilfe beim Auftreten von Stress und anderen unangenehmen Emotionen im Alltag. Gleichzeitig ersetzt es keine Form der Therapie und keinen Arztbesuch. Die Anwendung der hier beschriebenen Vorgehensweise erfolgt auf eigene Verantwortung.</p>'
    },
    support: {
        title: 'Ich möchte das IEG unterstützen',
        content: '<p>Wir möchten mit dieser Webseite allen Menschen die Anleitung zu ERES in ihrer eigenen Sprache zur Verfügung stellen und das Wissen um diese Resource auch mit anderen Initiativen verbreiten. Als gemeinnütziger Verein sind wir auf Spenden angewiesen, um dies zu ermöglichen. Wir freuen uns, wenn du uns dabei unterstützt mit einer Spende auf folgendes Konto:</p>' +
            'Zahlungsempfänger: Institut für Emotionale Gesundheit e. V.<br>' +
            'IBAN: DE13 4786 0125 0530 8813 00<br>' +
            'BIC: GENODEM1GTL<br>' +
            '<p>Wenn du eine Spendenquittung möchtest, gib bei der Überweisung bitte unbedingt deinen Namen und deine vollständige Adresse an, damit wir sie dir zuschicken können.</p>'
    }
}