<template>
  <div>
    <!-- Modal Trigger -->
    <a class="waves-effect waves-light btn modal-trigger" href="#language-modal"><i class="material-icons">language</i></a>

    <!-- Modal Structure -->
    <div id="language-modal" class="modal">
      <div class="modal-header"><a class="right modal-close"><i class="material-icons">close</i></a></div>
      <div class="modal-content">
        <div class="row">
          <div class="col s12 l6">
            <img src="../assets/images/towfiqu-barbhuiya-5u6bz2tYhX8-unsplash.jpg" width="100%">
          </div>
          <div class="col s12 l6">
            <h4 class="grey-text text-darken-3 lighten-3">{{ $t('header.languagePicker.title') }}</h4>
            <ul class='collection'>
              <a class="collection-item modal-close" v-on:click="changeLanguage('de')">deutsch</a>
              <a class="collection-item modal-close" v-on:click="changeLanguage('en')">english</a>
              <a class="collection-item modal-close" v-on:click="changeLanguage('es')">español</a>
              <a class="collection-item modal-close" v-on:click="changeLanguage('fr')">français</a>
              <a class="collection-item modal-close" v-on:click="changeLanguage('ru')">русский</a>
              <a class="collection-item modal-close" v-on:click="changeLanguage('ua')">український</a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'

export default {
  name: "LanguagePicker",
  methods: {
    changeLanguage: function (lang) {
      this.$i18n.locale = lang;
      setTimeout(function () {
        var toastHTML = document.getElementById('cookietext').innerHTML;
        M.toast({html: toastHTML, displayLength: 4_000});
      }, 100)

    }
  }
}
</script>

<style scoped>
.tap-target {
  background-color: #229bd6;
}
</style>