<template>
  <div id="basic-course-modal" class="modal">
    <div class="modal-content">
      <div class="modal-header"><a class="right modal-close"><i class="material-icons">close</i></a></div>
      <h4 class="modal-header">{{ $t('course.shortVersion.title') }}</h4>
      <div class="grey-text text-darken-3 lighten-3 flow-text" v-html="$t('course.shortVersion.content')"></div>
      <HelpCard shortVersionDisabled useVariant></HelpCard>
      <h5>{{ $t('disclaimer.title') }}</h5>
      <div v-html="$t('disclaimer.content')"></div>
    </div>
  </div>
</template>

<script>
import HelpCard from "@/components/HelpCard";

export default {
  name: "BasicCourseModal",
  components: {HelpCard}
}
</script>

<style scoped>

</style>