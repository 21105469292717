export default {
    header: {
        languagePicker: {
            title: 'Choisir la langue',
            button: 'Reprendre'
        },
        shortVersion: {
            link: 'Guide rapide ERES®'
        },
        longVersion: {
            link: 'Guide ERES®'
        }
    },
    footer: {
        privacy: {
            cookie: 'Nous n\'utilisons pas de cookies.',
            title: 'Déclaration de confidentialité'
        },
        partner: {
            title: 'Nos instituts partenaires'
        },
        images: {
            title: 'Images utilisées'
        }
    },
    sidePanel: {
        basicCourseCard: {
            title: 'Leitfaden',
            content: 'Sieh dir unseren Leitfaden an um schnell einen Überblick zu bekommen.',
            link: 'LIRE LE GUIDE RAPIDE'
        },
        aboutUs: {
            title: 'Qui sommes-nous?',
            content: 'L\'Institut de santé émotionnelle souhaite mettre à la disposition de toutes les personnes du monde entier le savoir que nous avons la capacité biologique de réguler par le corps les nombreuses formes d\'anxiété, de stress, de colère et de blocages que nous vivons. Il est important pour ses membres de mettre à disposition sur ce site des instructions précises sur la manière d\'utiliser cette ressource sur soi-même au quotidien. En Allemagne, en Autriche et en Suisse alémanique, on appelle l\'approche consciente de l\'utilisation de cette ressource biologique Emotional Resolution, en abrégé ERES®, (le sigle francophone est EmRes®).',
            link: 'En savoir plus',
            tltr: '<p>L\'Institut de santé émotionnelle (IEG) est une association à but non lucratif dont les formateurs et les coachs certifiés ont apporté les connaissances sur la méthode ERES® en Allemagne, en collaboration avec des chercheurs, des kinésithérapeutes et des médecins français depuis de nombreuses années. En France, la ressource naturelle de la méthode ERES® a été découverte dans les années 80 et a fait depuis l\'objet de recherches et de développements intensifs.</p>\n' +
                '<p>L\'objectif de l\'association est de promouvoir la santé émotionnelle des personnes et des animaux dans le monde entier. En utilisant la ressource biologique de l\'autorégulation émotionnelle, qui est à la disposition de tous les êtres vivants, les personnes peuvent peu à peu faire disparaître leurs anciens schémas émotionnels perturbateurs. Une sérénité et une joie de vivre accrues permettent ainsi une cohabitation plus pacifique entre les êtres humains et entre les humains et les animaux.</p>\n' +
                '<p>L\'objectif scientifique et social de l\'IEG est de comprendre et de transmettre comment notre cerveau crée et maintient des schémas émotionnels et comment les réguler de manière permanente en utilisant la méthode ERES. Voici quelques idées à ce sujet :</p>\n' +
                '<ul style="margin-left: 2em; list-style-position: inherit">' +
                '<li style="list-style-type: disc;font-weight: bold">Un enfant émotionnellement serein et satisfait apprend plus facilement à l\'école, s\'entend mieux avec ses frères et sœurs et ses amis et se sent ainsi plus joyeux et insouciant.</li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Les adolescents émotionnellement équilibrés ont moins besoin de drogues et de médicaments, s\'entendent mieux avec leurs parents et leur formation, et trouvent ainsi plus facilement leur chemin vers un avenir autonome.</li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Les adultes émotionnellement sereins sont des parents, des voisins, des amis et des collègues et des dirigeants plus équilibrés...</li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Les personnes qui régulent leurs propres schémas émotionnels et leurs peurs, et qui ne les expriment donc plus auprès de leur famille, de leurs collègues et de leurs animaux domestiques, sont des compagnons de route actifs pour une communauté mondiale plus pacifique.</li>' +
                '</ul>' +
                '<p>Pour des informations détaillées sur l\'origine et le fondement scientifique de l\'Emotional Resolution, voir : <a href="https://www.emotionalhealthinstitute.org/emotional-resolution">https://www.emotionalhealthinstitute.org/emotional-resolution</a></p>\n' +
                '<p>Pour des raisons de lisibilité, nous avons renoncé à l\'emploi du genre. Il va de soi que nous nous référons toujours à toutes les femmes et à tous les hommes et que nous prions nos lecteurs et lectrices de faire preuve de compréhension.</p>'
        },
        helpCard: {
            title: 'Besoin d\'aide ?',
            content2: 'Si tu ne veux pas l´essayer seul ou si tes problèmes te semblent très profonds, tu trouveras ici un soutien professionnel',
            content: 'As-tu peur des examens ? As-tu parfois des angoisses? As-tu peur du noir, des chiens, des araignées... ?\n',
            link: '@:sidePanel.basicCourseCard.link',
            linkEres: 'Aide professionnelle <i class="material-icons">open_in_new</i>',
            url: 'https://www.institut-sante-emotionnelle.fr/'
        }
    },
    welcome: {
        title: {
            value: 'Savais-tu...',
            value1: 'que tu peux réguler la peur, le stress et les inhibitions par le biais de ton corps ?'
        },
        content: {
            value: 'Ton corps sait comment faire, si tu le laisses faire. En effet, cette capacité biologique est innée chez l\'être humain et peut t\'apporter un soulagement dans les situations d\'urgence émotionnelle.',
            value1: 'Cela  peut t’aider lorsque tu te sens impuissant ou désespéré parce que tu ne sais pas comment t’y prendre. Ou si tu as peur, si tu es en colère ou si tu te sens paralysé...',
            value2: 'Nous pouvons en effet réguler le stress, la colère, l\'anxiété et le malaise par le biais de notre corps au quotidien. C\'est ce que la nature a prévu pour nous à l\'origine, mais nous, les humains, n\'avons plus accès directement à cette capacité naturelle depuis longtemps, en raison de certaines habitudes culturelles. Aujourd\'hui, nous savons comment l\'activer consciemment. Et c\'est entièrement gratuit !'
        }
    },
    purpose: {
        title: 'Qu\'est-ce que tu y gagnes ?',
        content: '<span style="font-size: 1em; font-weight: bold">TA VIE DEVIENT PLUS FACILE !</span>'
    },
    purpose2: {
        title: '',
        content: '<ul class="disc">' +
            '<li>Le stress dans ton quotidien diminue et tu deviens plus serein. Les relations avec ton entourage seront également moins stressantes et tu vivras plus sainement. Car - c\'est scientifiquement prouvé - une exposition prolongée au stress favorise les maladies et entretient les douleurs.</li>' +
            '<li>La réduction du stress t\'aidera, que tu sois à l\'école, en formation ou à l\'université, que tu travailles, que tu sois au chômage ou à la retraite, ou que tu t\'occupes d\'enfants, du ménage ou de ta famille.</li>' +
            '</ul>'
    },
    howTo: {
        title: 'Comment cela fonctionne-t-il ?',
        content: 'Une approche particulière a été développée pour activer consciemment notre capacité biologique à réguler le stress, les peurs et les blocages. Elle est connue sous le nom d\'Emotional Resolution ou ERES® dans le monde germanophone (et EmRes® dans le monde francophone) et est enseignée gratuitement par l\'Institut pour la santé émotionnelle (IEG). L\'activation consciente de cette capacité par la technique ERES® est facile à apprendre - même pour toi.'
    },
    course: {
        title: 'La procédure étape par étape :',
        step1: {
            title: 'Étape 1 : Préparation',
            preface: 'Pour te préparer, tu dois avoir pris conscience que ton corps réagit physiquement à la peur et au stress.',
            content:
                '<p>As-tu par exemple déjà pris conscience de la réaction de ton corps lorsque tu es en colère, ou l\'as-tu observée chez d\'autres personnes ?</p>' +
                '<p>En cas de grande colère ou d\'énervement, beaucoup de gens ont une sensation de chaleur. En outre, le visage peut devenir rouge, le cœur et la respiration s\'accélèrent, les muscles se tendent.</p>' +
                '<p>Si tu es en colère, triste ou stressé d\'une manière ou d\'une autre, essaie de voir par toi-même comment ton corps réagit. Prête également attention aux réactions corporelles désagréables, même si elles sont légères, car les réactions faibles et fortes indiquent le début du processus naturel de la Résolution émotionnelle (ERES®).</p>' +
                '<p>Si tu as essayé et si tu peux confirmer le vécu de cette expérience, passe à l\'étape 2.</p>'
        },
        step2: {
            title: 'Étape 2 : Choisis une situation',
            preface: 'Si tu veux essayer de réguler des émotions désagréables avec ERES, tu as besoin d\'une situation concrète de stress dans ta vie quotidienne (colère, peur, tristesse, blocages...).',
            content:
                '<p>Quelle situation veux-tu choisir ? Qu\'est-ce qui te dérange et te stresse dans ta vie ? De quoi as-tu peur ? Par quoi es-tu inhibé(e) ?</p>' +
                '<p>Exemples :' +
                '<ul class="disc">' +
                '<li>Tu es en colère à cause de quelque chose que quelqu\'un a dit.</li>' +
                '<li>Tu te sens abattu, impuissant et découragé en ce moment.</li>' +
                '<li>Tu as peur d\'un examen à venir ou tu as déjà eu un trou noir.</li>' +
                '<li>Tu es timide et tu as peur d\'aborder les autres.</li>' +
                '<li>Tu subis le vertige, la peur de l\'avion, la peur des araignées, la peur des chiens, la claustrophobie ?</li>' +
                '</ul>' +
                '</p>' +
                '<p>Dès que tu te trouves dans une situation quotidienne où tu vis quelque chose de ce genre, sois conscient de ton émotion et nomme-la. Il est maintenant particulièrement important que tu ne veuilles pas te débarrasser de l\'émotion, mais que tu l\'acceptes et l\'accueilles avec bienveillance, quelle que soit sa force ou son caractère désagréable à ce moment-là. Prends conscience de l\'endroit où l\'émotion se manifeste dans ton corps. La plupart du temps, nous ressentons directement cette première sensation qui est désagréable: par exemple une pression sur la poitrine, une douleur dans le ventre ou quelque chose d\'autre, même légèrement désagréable. Le mieux est de poser la main là où tu le perçois.</p>'
        },
        step3: {
            title: 'Étape 3 : Aperçu intérieur',
            preface: 'Maintenant, ferme les yeux ! Tu actives consciemment ta capacité naturelle à réguler la peur et le stress en observant exclusivement ce qui se manifeste dans ton corps à ce moment-là.',
            content:
                '<p><span class="bold">Ce qui est important ici :</span>' +
                '<ul class="disc">' +
                '<li>ne fais rien, ne bouge pas ! (Sois aussi passif et immobile qu\'une statue). Apprends à simplement percevoir pendant tout le processus et apprends à accepter et à accueillir chaque sensation physique qui se présente.</li>' +
                '<li>laisse de côté la situation qui a déclenché ton émotion auparavant ! N\'y pense plus et porte ton attention exclusivement sur tes sensations corporelles.</li>' +
                '</ul>' +
                '</p>' +
                '<p class="bold">Sinon, cela ne fonctionne pas !</p>' +
                '<p>Parfois, il est également nécessaire de se rendre dans un endroit où tu te sens suffisamment en sécurité pour fermer les yeux quelques minutes au calme et simplement percevoir tes sensations corporelles.</p>'
        },
        step4: {
            title: 'Étape 4 : Concentre-toi sur les sensations corporelles',
            preface: 'Continue à concentrer exclusivement sur tes sensations corporelles! Essaie de voir où tu peux ressentir et nommer une autre réaction corporelle désagréable.',
            content: '<p>Il peut s\'agir par exemple d\'un picotement désagréable à un endroit quelconque de ton corps, d\'une brûlure ou d\'une sensation de piqûre. Les battements de ton cœur peuvent s\'accélérer, ta respiration peut être plus rapide ou plus lente. Une douleur, une pression, une brûlure, une démangeaison ou autre peut se produire dans le dos ou ailleurs. Chacune de ces sensations corporelles que tu constates en second lieu t\'indique que ta compétence naturelle à réguler une émotion désagréable ou un stress est actuellement activée. Pour en être plus conscient, tu peux aussi poser une main à cet endroit.</p>' +
                '<p>Cette deuxième sensation corporelle désagréable et les autres qui suivent sont présentes dans chaque émotion stressante que nous vivons au quotidien. Avec un peu d\'entraînement, nous les percevons très rapidement, à partir du moment où nous avons fermé les yeux.</p>' +
                '<p>Dès que tu auras essayé cela plusieurs fois avec différentes émotions et différents stress, tu te rendras compte que notre corps réagit en fait toujours, à chaque émotion.</p>'
        },
        step5: {
            title: 'Étape 5 : Suis la progression des sensations de ton corps',
            preface: 'Suis avec ton attention la suite des événements dans ton corps. Ne fais rien ! Apprends à observer de manière totalement passive ! Laisse toutes les sensations corporelles se modifier d\'elles-mêmes jusqu\'à ce qu\'elles se calment !',
            content: '<p>Elles peuvent apparaître l\'une après l\'autre ou simultanément. Le déroulement normal est que - si tu ne bouges pas ou n\'agis pas d\'une manière ou d\'une autre - elles se développent d\'elles-mêmes ! Cela ne dure pas longtemps. Après deux ou trois minutes - souvent plus rapidement - ton corps se sentira à nouveau bien et toutes les réactions corporelles se seront calmées.</p>' +
                '<p>Pour cette étape, il est utile de savoir :' +
                '<ul class="disc">' +
                '<li>Chaque stress, chaque peur, chaque "je ne peux pas le faire" est lié à une accumulation spécifique de sensations corporelles particulières. Tu peux l\'imaginer comme un film qui se répète à chaque fois que tu vis une émotion particulière. Si tu utilises ERES avec différentes émotions, tu le constateras.</li>' +
                '<li>La plupart des sensations corporelles qui apparaissent ne sont généralement désagréables que pendant une courte période et changent très rapidement , si tu les acceptes avec bienveillance et les accueilles en toi.  Cela vaut également pour les réactions corporelles violentes qui apparaissent parfois.</li>' +
                '</ul></p>'
        },
        step6: {
            title: 'Étape 6 : Lorsque tout s\'est calmé',
            preface: 'Ouvre les yeux dès que toutes les sensations corporelles désagréables se sont calmées.',
            content: 'Laisse-toi quelques secondes pour te reconnecter à l\'extérieur. Prends à nouveau conscience de ton environnement et de l\'endroit où tu te trouves. Comment te sens-tu ?'
        },
        step7: {
            title: 'Étape 7 : Est-ce que tout est résolu ?',
            preface: 'Vérifie ensuite si tout est résolu : certains problèmes émotionnels nécessitent deux passages.',
            content: '<p>Pose-toi la question suivante : "Si je repense à ce qui vient de me stresser ou de m\'angoisser, à ce qui m\'a bloqué ou désespéré, comment je me sens maintenant ?"</p>' +
                '<p>Est-ce que tu te sens seulement "mieux" ? Les émotions sont-elles moins fortes, mais elles n\'ont pas disparu ?</p>' +
                '<p>Dans ce cas, fais un autre passage, exactement identique. Demande-toi à nouveau : "A quel autre endroit de mon corps mon émotion se manifeste-t-elle ?\n' +
                'et répète les étapes 2 à 5 exactement comme la première fois. "</p>' +
                '<p>Si tout va bien et que tu te sens déjà émotionnellement équilibré après le premier passage, tu as utilisé avec succès ta capacité naturelle de régulation. Félicitations !</p>' +
                '<p>Tu pourras constater au quotidien si tout ce qui était lié au stress initial est effectivement régulé dès que tu réagiras calmement à une situation similaire.</p>' +
                '<p>Il se peut que d\'autres émotions sous-jacentes apparaissent, qui te sont également désagréables. Pour que tu n\'aies pas l\'impression que l\'ERES n\'a pas fonctionné pour toi, il est important de nommer précisément les émotions avec lesquelles tu fais l\'ERES. Tu pourras ainsi les distinguer plus facilement des autres émotions que tu ressens dans des situations stressantes similaires. Il est également utile de les noter.</p>' +
                '<p>Chaque autre émotion désagréable que nous vivons nécessite une application spécifique d\'ERES. Une fois que tu as reconnu l\'efficacité d\'ERES, il est judicieux de l\'utiliser aussi souvent que possible. Plus tu le feras, plus tu te sentiras serein, intrépide, libre intérieurement et<br>courageux !</p>'
        },
        tips: {
            title: 'Conseils - si ça n\'a pas marché du premier coup',
            1: {
                title: 'Tu éprouves des sensations corporelles très violentes et désagréables :',
                content: '<p>Parfois, lors d\'émotions très violentes, des sensations corporelles très intenses apparaissent à court terme. Celles-ci peuvent être : un fort pincement, des tremblements, des difficultés à respirer, des vertiges ou des nausées...</p>' +
                    '<p>Notre réaction spontanée est souvent de nous concentrer sur ces réactions corporelles désagréables ou d\'ouvrir les yeux. C\'est dommage, car nous interrompons ainsi le processus. Pour que cela ne t\'arrive pas, il est important pour toi de n\'observer que passivement, même dans ce cas. Pour cela, tu dois savoir que même les sensations désagréables les plus violentes se modifient et s\'apaisent en quelques secondes si tu les laisses simplement se modifier.</p>'
            },
            2: {
                title: 'Que signifie "observer passivement" ?',
                content: 'Dans ce guide sur l\'ERES®, il t\'est conseillé à plusieurs reprises de rester passif et de ne vraiment rien faire pendant que tu as les yeux fermés. Il s\'agit de ne pas influencer le déroulement des sensations corporelles de quelque manière que ce soit : ni par une respiration active, ni en pensant à la situation initiale ou à d\'autres situations similaires. Tout mouvement est également une manière de devenir actif. La plupart du temps, nous le faisons inconsciemment afin d\'atténuer ou d\'éviter de ressentir des sensations désagréables. Ce n\'est pas nécessaire, car tout change de toute façon très rapidement en mode passif et s\'apaise de lui-même. Toute forme d\'influence ralentit ou empêche le déroulement naturel de l\'ERES®.'
            },
            3: {
                title: 'Prendre conscience des bruits extérieurs',
                content: '<p>Pour beaucoup d\'entre nous, l\'application d\'ERES® est plus facile si nous laissons une partie de notre attention à l\'extérieur. Les sensations corporelles parfois violentes se modifient ainsi plus rapidement.</p><p>' +
                    'À quoi cela est-il dû ? Lorsque, les yeux fermés, nous percevons simultanément ce qui se passe à l\'intérieur de notre corps ET dans notre environnement, la probabilité est la plus grande que les réactions corporelles émotionnelles stockées dans notre mémoire corporelle comme dans un film puissent se succéder, se modifier et s\'apaiser sans être perturbées.' +
                    '</p>'
            },
            4: {
                title: 'Tu gardes à tout moment le contrôle de ce qui se passe :',
                content: '<p>si les sensations corporelles qui se produisent sont trop désagréables pour toi, ou si tu ne te sens pas parfaitement à l\'aise là où tu es avec les yeux fermés, tu peux à tout moment rouvrir les yeux et mettre ainsi fin au processus.</p>' +
                    '<p>Dès que l\'émotion que tu voulais réguler avec ERES® réapparaît dans ta vie quotidienne, une nouvelle tentative de la réguler directement est possible.</p>'
            }
        },
        next: {
            title: 'Pour te rendre encore plus familier avec la méthode ERES® :',
            content: '<p>Cela vaut la peine de noter chaque expérience que tu fais avec l\'application de la Résolution émotionnelle (ERES®). Pour cela, il suffit de noter les trois premières sensations corporelles de chaque émotion qui sont apparues. Tu constateras ainsi que chaque émotion a sa propre signature et sa propre séquence et qu\'elle est stockée dans notre mémoire corporelle comme dans une bibliothèque.</p>' +
                '<p>Utilise un carnet de notes ou dicte tes expériences sur ton smartphone.</p>' +
                '<p>Et raconte-les à d\'autres. La nature est ainsi faite que nous oublions souvent complètement les peurs, le stress et les inhibitions qui se sont durablement régulés si nous n\'avons pas noté ou partagé ces expériences avec d\'autres. Nous nous souviendrons encore mieux de l\'utilité de cette capacité si nous racontons aux autres comment nous avons réussi à réguler une peur ou un stress particulier.</p>'
        },
        limits: {
            title: 'Limites de l\'ERES® et responsabilité individuelle',
            content: '<p>Du point de vue de l\'IEG, le fait de savoir comment utiliser notre capacité naturelle à pouvoir dissoudre nous-mêmes des émotions désagréables représente une chance particulière. En même temps, il est important d\'être responsable de l\'apprentissage de ce processus.</p>'
        },
        shortVersion: {
            title: 'Guide rapide d\'utilisation de la méthode ERES®',
            content: '<ul class="disc"><li>Sois attentif aux expériences concrètes de stress dans ta vie quotidienne (colère, peur, tristesse, blocages...) pour appliquer la méthode ERES® !</li>' +
                '<li>Dès que tu vis une émotion désagréable, que tu te rappelles que tu peux utiliser la méthode ERES® et que tu te trouves dans un endroit sûr, nomme l\'émotion et accueille-la avec bienveillance. Pose-toi ensuite la question suivante : \n' +
                '"Où la ressens-je dans mon corps en ce moment ?" Accueille également avec bienveillance toutes les autres sensations corporelles qui apparaissent.</li>' +
                '<li>Si tu le souhaites, pose une main à l\'endroit où tu perçois une sensation corporelle désagréable. Cela facilite le ressenti passif dans le corps.</li>' +
                '<li>Ferme maintenant les yeux !</li>' +
                '<li>Où constates-tu une deuxième sensation corporelle désagréable ? Si tu le souhaites, pose également une main à cet endroit.</li>' +
                '<li>Ne fais rien !</li>' +
                '<li>Observe avec bienveillance ce qui se passe dans ton corps et comment de nouvelles sensations corporelles apparaissent les unes après les autres et se modifient sans cesse ! Tu peux aussi suivre ces changements avec tes mains.</li>' +
                '<li>Fais cela jusqu\'à ce que toutes les sensations corporelles se soient calmées ! Cela prend environ deux à trois minutes ; parfois, cela peut aller plus vite.</li>' +
                '<li>Quand il n\'y a plus de sensations corporelles désagréables, ouvre les yeux !</li>' +
                '<li>Reprends conscience de l’extérieure et demande-toi maintenant : "Si je pense à nouveau à ce qui vient de me stresser, comment est-ce que je me sens maintenant ?"</li>' +
                '<li>Si tu te sens "seulement mieux", mais que l\'émotion n\'est pas encore complètement résolue, fais un deuxième passage identique ! Accueille à nouveau l\'émotion et pose-toi la question : "Où est-ce que je la sens dans mon corps ?"</li>' +
                '<li>Si tout va déjà bien après le premier passage et que tu te sens équilibré sur le plan émotionnel, tu as utilisé ERES® avec succès. Dans des situations quotidiennes similaires, tu en auras la confirmation : Tu es resté calme cette fois-ci. </li></ul>'
        }
    },
    disclaimer: {
        title: 'Clause de non-responsabilité',
        content: '<p>ERES est une forme efficace de première aide lors de l\'apparition de stress et d\'autres émotions désagréables dans la vie quotidienne. Elle ne remplace aucune forme de thérapie ni aucune consultation médicale. L\'utilisation de la procédure décrite ici se fait sous votre propre responsabilité.</p>' +
            '<p>Toutes les informations sont fournies sans garantie de la part des auteurs ou du IEG. Toute responsabilité du IEG ou de ses mandataires pour des dommages corporels, matériels ou financiers est donc exclue.</p>',
        contentShort: '<p>ERES est une forme efficace de première aide lors de l\'apparition de stress et d\'autres émotions désagréables dans la vie quotidienne. Elle ne remplace aucune forme de thérapie ni aucune consultation médicale. L\'utilisation de la procédure décrite ici se fait sous votre propre responsabilité.</p>'
    },
    support: {
        title: 'Je souhaite soutenir le IEG',
        content: '<p>Nous souhaitons, par le biais de ce site, mettre à la disposition de tous le mode d\'emploi d\'ERES dans leur propre langue et diffuser les connaissances relatives à cette ressource par le biais d\'autres initiatives. En tant qu\'association à but non lucratif, nous dépendons des dons pour rendre cela possible. Nous serions heureux que tu nous soutiennes en faisant un don sur le compte suivant :</p>' +
            'Bénéficiaire du paiement : Institut für Emotionale Gesundheit e. V.<br>' +
            'IBAN : DE13 4786 0125 0530 8813 00<br>' +
            'BIC : GENODEM1GTL<br>' +
            '<p>Si tu souhaites recevoir un reçu de don, indique impérativement ton nom et ton adresse complète lors du virement, afin que nous puissions te l’envoyer.</p>'
    }
}