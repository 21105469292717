<template>
  <div id="privacy-modal" class="modal">
    <div class="modal-header"><a class="right modal-close"><i class="material-icons">close</i></a></div>
    <div class="modal-content">
      <div class="modal-header"><h4>Datenschutzerklärung</h4></div>
      <p>You can find the english version below.</p>
      <h5>Grundlegendes</h5>

      Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und
      Verwendung personenbezogener Daten durch den Websitebetreiber (siehe Impressum) informieren.

      Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und
      entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser
      Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen, sich die
      Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.

      Definitionen der verwendeten Begriffe (z. B. „personenbezogene Daten“ oder „Verarbeitung“) finden Sie in Art. 4
      DSGVO.

      <h5>Zugriffsdaten</h5>

      Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses (s. Art. 6, Abs. 1
      lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf dem Server der
      Website ab. Folgende Daten werden so protokolliert:
      <ul>
        <li>- Besuchte Website</li>
        <li>- Uhrzeit zum Zeitpunkt des Zugriffes</li>
        <li>- Menge der gesendeten Daten in BYTE</li>
        <li>- Quelle/Verweis von welchem Sie auf die Seite gelangten</li>
        <li>- Verwendeter Browser</li>
        <li>- Verwendetes Betriebssystem</li>
        <li>- Verwendete IP-Adresse</li>
      </ul>
      Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die Speicherung der Daten
      erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus Beweisgründen
      aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.

      <h5>Umgang mit Kontaktdaten</h5>

      Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden Ihre
      Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen werden kann.
      Wir verarbeiten personenbezogene Daten wie Vorname, Nachname, IP-Adresse, E-Mail-Adresse, Wohnort, Postleitzahl
      und Inhaltsangaben aus dem Kontaktformular. Wir verarbeiten personenbezogene Daten nur nach ausdrücklicher
      Erlaubnis der betreffenden Nutzer und unter Einhaltung der geltenden Datenschutzbestimmungen, wobei wir die
      Kontaktaufnahme ihrerseits als ausdrückliche Erlaubnis werten. Die Verarbeitung der personenbezogenen Daten
      erfolgt aufgrund unseres berechtigten Interesses zur Erfüllung unserer vertraglich vereinbarten Leistungen und zur
      Optimierung unseres Online-Angebotes. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.

      <h5>Rechte des Nutzers</h5>

      Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen
      Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die
      Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr
      Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden,
      können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.

      <h5>Löschung von Daten</h5>

      Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung)
      kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Date werden, sollten sie für ihre
      Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls
      eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind,
      erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere
      Zwecke verarbeitet.

      <h5>Widerspruchsrecht</h5>

      Nutzer dieser Website können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer
      personenbezogenen Daten zu jeder Zeit widersprechen. Wenn sie eine Berichtigung, Sperrung, Löschung oder Auskunft
      über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung,
      Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten,
      wenden Sie sich bitte an folgende E-Mail-Adresse:
      info@einfühlsame-kommunikation.de

      <h4 style="margin-top: 1em">Privacy policy</h4>
      <h5>Basic</h5>

      This privacy policy is intended to inform the users of this website about the nature, scope and purpose of the
      collection and use of personal data by the website operator (see imprint).
      The website operator takes your data protection very seriously and treats your personal data confidentially and in
      accordance with the statutory provisions. As new technologies and the constant further development of this website
      may result in changes to this data protection declaration, we recommend that you read through it again at regular
      intervals.

      Definitions of the terms used (e.g. "personal data" or "processing") can be found in Art. 4 DSGVO.

      <h5>Access data</h5>

      We, the website operator or page provider, collect data on accesses to the website on the basis of our legitimate
      interest (see Art. 6, Para. 1 lit. f. DSGVO) and store these as "server log files" on the website server. The
      following data is logged in this way:
      <ul>
        <li>- Visited website</li>
        <li>- Time at the time of access</li>
        <li>- Amount of data sent in BYTE</li>
        <li>- Source/reference from which you came to the site</li>
        <li>- Browser used</li>
        <li>- Operating system used</li>
        <li>- IP address used</li>
      </ul>
      The server log files are stored for a maximum of 7 days and then deleted. The data is stored for security reasons,
      e.g. to be able to clarify cases of misuse. If data must be retained for evidentiary reasons, it is exempt from
      deletion until the incident has been finally clarified.

      <h5>Dealing with contact data</h5>

      If you contact us as the website operator using the contact options offered, your details will be stored so that
      they can be used to process and respond to your enquiry. We process personal data such as first name, surname, IP
      address, e-mail address, place of residence, postcode and content details from the contact form. We only process
      personal data with the express permission of the users concerned and in compliance with the applicable data
      protection regulations, whereby we consider the contacting of them as express permission. The processing of
      personal data is based on our legitimate interest in fulfilling our contractually agreed services and optimising
      our online offer. This data will not be passed on to third parties without your consent.

      <h5>Rights of the user</h5>

      As a user, you have the right to obtain, on request and free of charge, information about what personal data has
      been stored about you. You also have the right to have inaccurate data corrected and to have your personal data
      processed restricted or deleted. If applicable, you can also exercise your right to data portability. If you
      believe that your data has been processed unlawfully, you can lodge a complaint with the competent supervisory
      authority.

      <h5>Deletion of data</h5>

      Unless your request conflicts with a legal obligation to retain data (e.g. data retention), you have the right to
      have your data deleted. Data stored by us will be deleted if it is no longer required for its intended purpose and
      there are no legal retention periods. If deletion cannot be carried out because the data is required for
      permissible legal purposes, data processing will be restricted. In this case, the data will be blocked and not
      processed for other purposes.

      <h5>Right to object</h5>

      Users of this website may exercise their right to object to the processing of their personal data at any time. If
      you wish to have your personal data corrected, blocked, deleted or informed about the personal data stored about
      you, or if you have any questions regarding the collection, processing or use of your personal data, or if you
      wish to withdraw your consent, please contact us at the following e-mail address:
      info@einfühlsame-kommunikation.de

      The IEG and its legal representatives assume no responsibility for the content and data protection of external
      sites.
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyModal"
}
</script>

<style scoped>

</style>