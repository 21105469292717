<template>
  <div>
    <footer class="page-footer">
      <div class="container">
        <div class="row">
          <div class="col l6 s12">
            <h5 class="white-text">Institut für Emotionale Gesundheit e. V.</h5>
            <ul>
              <li><a class="modal-trigger  grey-text text-lighten-4 mr-1" href="#imprint-modal"><span
                  v-if="this.$i18n.locale !== 'en'">Impressum / </span>Imprint</a></li>
              <li><a class="modal-trigger  grey-text text-lighten-4"
                     href="#privacy-modal">{{ $t('footer.privacy.title') }}</a></li>
              <li><a class="modal-trigger grey-text text-lighten-4" href="#general-modal"><span
                  v-if="this.$i18n.locale !== 'en'">Allgemeines zur Nutzung von ERES® / </span>General information on
                the use of ERES®</a></li>
              <li><a class="modal-trigger grey-text text-lighten-4" href="#limitations-modal"><span
                  v-if="this.$i18n.locale !== 'en'">Haftungsbeschränkung / </span>Limitation of liability</a></li>
            </ul>
          </div>
          <div class="col l4 offset-l2 s12">
            <h5 class="white-text">{{ $t('footer.partner.title') }}</h5>
            <ul>
              <li><a class="grey-text text-lighten-3" href="https://www.emotionalhealthinstitute.org" target="_blank">www.emotionalhealthinstitute.org
                <i class="material-icons tiny">open_in_new</i></a></li>
              <li><a class="grey-text text-lighten-3" href="https://www.institut-sante-emotionnelle.fr" target="_blank">www.institut-sante-emotionnelle.fr
                <i class="material-icons tiny">open_in_new</i></a></li>
              <li><a class="grey-text text-lighten-3" href="https://www.alchemyforlife.co.jp/emres/about_emres"
                     target="_blank">www.alchemyforlife.co.jp/emres/about_emres <i class="material-icons tiny">open_in_new</i></a>
              </li>
            </ul>
            <h5 class="white-text">{{ $t('footer.images.title') }}</h5>
            <ul>
              <li>Photos found on <a href="https://unsplash.com/" target="_blank">unsplash.com<i
                  class="material-icons tiny">open_in_new</i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="container">
          © 2022 Institut für Emotionale Gesundheit e. V.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    lang: String
  },
  mounted() {
    this.lang = this.$i18n.locale;
  }
}
</script>

<style scoped>
.mr-1 {
  margin-right: 0.5em;
}

.page-footer {
  background-color: #213e57;

}

.page-footer .footer-copyright {
  background-color: #212C40;
}
</style>