<template>
  <div id="general-modal" class="modal">
    <div class="modal-header"><a class="right modal-close"><i class="material-icons">close</i></a></div>
    <div class="modal-content">
      <div class="modal-header"><h4><span v-if="this.$i18n.locale !== 'en'">Allgemeines zur Nutzung von ERES® / </span>General
        information on the use of ERES®</h4></div>
      <div v-if="this.$i18n.locale !== 'en'">
        <h5>Allgemeines zur Nutzung von ERES®</h5>
        <p>ERES® ist eine effektive Form der ersten Hilfe beim Auftreten von Stress und anderen unangenehmen Emotionen
          im Alltag. Gleichzeitig ersetzt es keine Form der Therapie und keinen Arztbesuch. Die Anwendung der hier
          beschriebenen Vorgehensweise erfolgt auf eigene Verantwortung.</p>
        <p>Alle Angaben erfolgen ohne Gewährleistung oder Garantie seitens der Autoren bzw. des IEGs. Eine Haftung des
          IEGs bzw. seiner Beauftragten für Personen-, Sach- und Vermögensschäden ist daher ausgeschlossen.</p>
      </div>
      <h5>General information on the use of ERES®</h5>
      <p>ERES® is an effective form of first aid when stress and other unpleasant emotions arise in everyday life. At
        the same time, it does not replace any form of therapy or consultation with a doctor. To perform the procedure
        described here is entirely at your own risk.</p>
      <p>All information is provided without warranty or guarantee on the part of the authors or the IEG. Any liability
        of the IEG or its agents for personal injury, property damage or financial loss is therefore excluded.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralInformationModal"
}
</script>

<style scoped>

</style>