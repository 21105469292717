<template>
  <div id="about-us-modal" class="modal">
    <div class="modal-header"><a class="right modal-close"><i class="material-icons">close</i></a></div>
    <div class="modal-content">
      <div class="right">
        <img src="../assets/images/alp-duran-SwMgX1d0Kmw-unsplash.jpg" style="height: 200px;">
      </div>
      <h4 class="card-title grey-text text-darken-3 lighten-3">{{ $t('sidePanel.aboutUs.title') }}</h4>
      <p class="grey-text text-darken-3 lighten-3" v-html="$t('sidePanel.aboutUs.content')"></p>
      <p class="grey-text text-darken-3 lighten-3" v-html="$t('sidePanel.aboutUs.tltr')"></p>
      <ul class="collapsible" data-collapsible="accordion">
        <li>
          <div class="collapsible-header bold">{{ $t('support.title') }} <i class="material-icons">arrow_drop_down</i>
          </div>
          <div class="collapsible-body" v-html="$t('support.content')"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsModal"
}
</script>

<style scoped>

</style>