<template>
  <div v-bind:id="id">
    <img v-if="image != '' && image != undefined" :src="require(`@/assets/images/${image}`)"
         class="section-image hide-on-large-only hide-on-extra-large-only">
    <div v-if="image != '' && image != undefined" class="local-parallax hide-on-med-and-down"
         v-bind:style="{backgroundImage: 'url(' + require(`@/assets/images/${image}`) + ')'}"></div>
    <div v-if="!onlyImage" class="value">
      <h3 class="header">{{ $t(step + '.title') }}</h3>
      <h4 v-if="step.includes('step')">{{ $t(step + '.preface') }}</h4>
      <p class="grey-text text-darken-3 lighten-3 flow-text" v-html="$t(step+'.content')"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    id: String,
    step: String,
    image: String,
    onlyImage: Boolean
  }
}
</script>

<style scoped>
.header {
  margin-top: 0;
}

.section-image, .section-image + .local-parallax {
  margin-top: 2.3rem;
  margin-bottom: 1.7rem;
  height: 250px;
  width: 100%;
  object-fit: cover;
}

.flow-text {
  text-align: justify;
}

@media only screen {
  .local-parallax {
    margin-top: 2.3rem;
    /* Set a specific height */
    min-height: 250px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-device-width: 992px) {
  .margin-top-1 {
    margin-top: 1.5em;
  }
}
</style>