<template>
  <div id="app">
    <Header></Header>
    <main class="">
      <div class="parallax-container short">
        <div class="parallax">
          <img src="@/assets/images/happiness-g68cf87109_1920.jpeg">
        </div>
      </div>
      <div class="section white">
        <div class="container">
          <div class="stage">
            <div class="align-botttom">
              <div class="card-panel">
                <h2>{{ $t('welcome.title.value') }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col 12 xl9">
              <h3>{{ $t('welcome.title.value1') }}</h3>
              <p class="grey-text text-darken-3 lighten-3 flow-text">{{ $t('welcome.content.value') }}</p>
              <p class="grey-text text-darken-3 lighten-3 flow-text">{{ $t('welcome.content.value1') }}</p>
              <p class="grey-text text-darken-3 lighten-3 flow-text">{{ $t('welcome.content.value2') }}</p>
              <Section step="purpose"></Section>
              <Section image="fredrik-solli-wandem-vkvuSqAah80-unsplash.jpeg" step="purpose2"></Section>
              <Section id="howTo" step="howTo"></Section>
              <h2>{{ $t('course.title') }}</h2>
              <img v-if="$i18n.locale == 'de'" src="@/assets/images/eres_8_steps_v2.png" style="width: 100%;">
              <img v-if="$i18n.locale == 'fr'" src="@/assets/images/eres_8_steps_v2_fr.png" style="width: 100%;">
              <img v-if="$i18n.locale == 'en'" src="@/assets/images/eres_8_steps_v2_en.png" style="width: 100%;">
              <img v-if="$i18n.locale == 'ua'" src="@/assets/images/eres_8_steps_ua.jpg" style="width: 100%;">
              <img v-if="$i18n.locale == 'ru'" src="@/assets/images/eres_8_steps_ru.jpg" style="width: 100%;">
              <img v-if="$i18n.locale == 'es'" src="@/assets/images/eres_8_steps_es.jpg" style="width: 100%;">
              <Section id="Kapitel1" image="" step="course.step1"></Section>
              <Section id="Kapitel2" image="forest-g44dfbde7d_1920.jpeg" step="course.step2"></Section>
              <Section id="Kapitel3" image="jared-rice-8w7b4SdhOgw-unsplash.jpg" step="course.step3"></Section>
              <Section id="Kapitel4" image="grass-g075478bd2_1920.jpeg" step="course.step4"></Section>
              <Section id="Kapitel5" image="dolphin-gdbad8f1f8_1920.jpeg" step="course.step5"></Section>
              <Section id="Kapitel6" image="s-migaj-Yui5vfKHuzs-unsplash.jpg" step="course.step6"></Section>
              <Section id="Kapitel7" image="zoltan-tasi-vHnVtLK8rCc-unsplash.jpg" step="course.step7"></Section>
              <h2>{{ $t('course.tips.title') }}</h2>
              <ul class="collapsible" data-collapsible="accordion">
                <li>
                  <div class="collapsible-header bold">{{ $t('course.tips.1.title') }} <i class="material-icons">arrow_drop_down</i>
                  </div>
                  <div class="collapsible-body" v-html="$t('course.tips.1.content')"></div>
                </li>
                <li>
                  <div class="collapsible-header bold">{{ $t('course.tips.2.title') }} <i class="material-icons">arrow_drop_down</i>
                  </div>
                  <div class="collapsible-body" v-html="$t('course.tips.2.content')"></div>
                </li>
                <li>
                  <div class="collapsible-header bold">{{ $t('course.tips.3.title') }} <i class="material-icons">arrow_drop_down</i>
                  </div>
                  <div class="collapsible-body" v-html="$t('course.tips.3.content')"></div>
                </li>
                <li>
                  <div class="collapsible-header bold">{{ $t('course.tips.4.title') }} <i class="material-icons">arrow_drop_down</i>
                  </div>
                  <div class="collapsible-body" v-html="$t('course.tips.4.content')"></div>
                </li>
              </ul>
              <div class="card">
                <div class="card-action">
                  <a class="modal-trigger" href="#basic-course-modal">{{ $t('sidePanel.basicCourseCard.link') }}</a>
                </div>
              </div>
              <Section step="course.next"></Section>
              <Section step="course.limits"></Section>
              <p v-html="$t('disclaimer.contentShort')"></p>
              <HelpCard shortVersionDisabled useVariant></HelpCard>
              <Section id="lastImage" image="wolfgang-hasselmann-J-Hv3jFsMZ8-unsplash.jpeg" onlyImage></Section>
              <ul class="collapsible" data-collapsible="accordion">
                <li>
                  <div class="collapsible-header bold">{{ $t('support.title') }} <i class="material-icons">arrow_drop_down</i>
                  </div>
                  <div class="collapsible-body" v-html="$t('support.content')"></div>
                </li>
              </ul>
            </div>
            <div class="sidepanel col 12 xl3 hide-on-med-and-down hide-on-large-only show-on-extra-large">
              <HelpCard eres-disabled></HelpCard>
              <AboutUsCard></AboutUsCard>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
    <BasicCourseModal></BasicCourseModal>
    <AboutUsModal></AboutUsModal>
    <PrivacyModal></PrivacyModal>
    <ImprintModal></ImprintModal>
    <GeneralInformationModal></GeneralInformationModal>
    <LimitationsModal></LimitationsModal>
    <div id="cookietext" class="hidden">{{ $t('footer.privacy.cookie') }}</div>
  </div>
</template>

<script>
import M from 'materialize-css'
import Header from '@/components/Header'
import Section from "@/components/Section";
import HelpCard from "@/components/HelpCard";
import AboutUsCard from "@/components/AboutUsCard";
import BasicCourseModal from "@/components/BasicCourseModal";
import AboutUsModal from "@/components/AboutUsModal";
import PrivacyModal from "@/components/PrivacyModal";
import ImprintModal from "@/components/ImprintModal";
import LimitationsModal from "@/components/LimitationsModal";
import GeneralInformationModal from "@/components/GeneralInformationModal";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    PrivacyModal,
    ImprintModal,
    GeneralInformationModal,
    LimitationsModal,
    AboutUsModal,
    BasicCourseModal,
    AboutUsCard,
    Header,
    Section,
    HelpCard
  },
  mounted() {
    M.AutoInit();
    var toastHTML = document.getElementById('cookietext').innerHTML;
    M.toast({html: toastHTML, displayLength: 4_000});
  }
}
</script>

<style scoped>
.section-image, .section-image + .local-parallax {
  margin-top: 2.3rem;
  margin-bottom: 1.7rem;
  height: 250px;
  width: 100%;
  object-fit: cover;
}

.parallax-container .short {
  height: 450px;
}

@media only screen and (max-device-width: 992px) {

  .parallax-container .short {
    height: 150px;
  }
}

.stage {
  margin-top: -250px;
  margin-bottom: 80px;
}

.stage .card-panel {
  background-color: transparent;
  color: #5eccfb;
  text-shadow: 2px 1px #1e608c;
}

.stage .card-panel > * {
  opacity: 1;
}

</style>
