export default {
    header: {
        languagePicker: {
            title: 'Select language',
            button: 'select'
        },
        shortVersion: {
            link: 'Quick Guide ERES®'
        },
        longVersion: {
            link: 'Guide ERES®'
        }
    },
    footer: {
        privacy: {
            cookie: 'We do not use cookies',
            title: 'Privacy policy'
        },
        partner: {
            title: 'Our partner institutes'
        },
        images: {
            title: 'Used images'
        }
    },
    sidePanel: {
        basicCourseCard: {
            title: 'Guide',
            link: 'Read Quick Start Guide'
        },
        aboutUs: {
            title: 'About Us',
            content: 'The Institute for Emotional Health aims to make available to all people worldwide the knowledge that we have the biological ability to dissolve the many forms of anxiety, stress, anger and blockages by the means of our body. It is an important concern of its members to make available on this homepage precise instructions on how to apply this resource to oneself in everyday life. In Germany, Austria and German-speaking Switzerland, the conscious approach of applying the biological resource Emotional Resolution, is called ERES® for short (the English abbreviation is EmRes®).',
            link: 'Learn more',
            tltr: '<p>The Institute for Emotional Health (IEG) is a non-profit association whose certified trainers and coaches have brought the knowledge of ERES® to Germany through many years of cooperation with French researchers, physiotherapists and doctors. In France, the natural resource of ERES® was discovered in the 1980s and has been intensively researched and developed since then.</p>\n' +
                '<p>The aim of the association is to promote the emotional health of people and animals worldwide. By using the biological resource of emotional self-regulation, which is available to all living beings, people can gradually dissolve their old, disturbing emotional patterns. As a result, they no longer transfer their own stress and fears to their fellow human beings and animals. Increasing serenity and the joy of life and thus enable people to live more peacefully with each other and with animals.</p>\n' +
                '<p>The scientific and social focus of the IEG is to understand and pass on how our brain creates and maintains emotional patterns and how they can be permanently dissolved by using ERES®. Here are some ideas:</p>\n' +
                '<ul style="margin-left: 2em; list-style-position: inherit">' +
                '<li style="list-style-type: disc;font-weight: bold">An emotionally calm and content child learns more easily at school, gets along better with siblings and friends, and feels happier and more carefree as a result.</li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Emotionally balanced adolescents need fewer drugs and medication, get along better with their parents and their education, and thus find their way more easily into a self-reliant future.</li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">Emotionally calm adults are more balanced parents, neighbours, friends and colleagues, and leaders...</li>\n' +
                '<li style="list-style-type: disc;font-weight: bold">People who dissolve their own emotional patterns and fears, and who will no longer act them out on their families, colleagues and pets, are active contributors to a more peaceful world community.</li>' +
                '</ul>' +
                '<p>For detailed information on the origin and scientific basis of Emotional Resolution see: <a href="https://www.emotionalhealthinstitute.org/emotional-resolution">https://www.emotionalhealthinstitute.org/emotional-resolution</a></p>'
        },
        helpCard: {
            title: 'Do you need help?',
            content2: 'If you do not want to try it on your own or your problems seem very deep, you can find professional support here:',
            content: 'Are you afraid of exams? Do you have existential fear? Are you afraid of the dark, dogs or spiders…?',
            link: '@:sidePanel.basicCourseCard.link',
            linkEres: 'Professional help <i class="material-icons">open_in_new</i>',
            url: 'https://www.emotionalhealthinstitute.org'
        }
    },
    welcome: {
        title: {
            value: 'Did you know, ...',
            value1: 'that you can dissolve fear, stress and inhibitions through your body?'
        },
        content: {
            value: 'Your body also knows how to do this if you let it, because this biological ability is inborn to us humans and can bring relief in emotional emergencies.',
            value1: 'It helps you when you feel helpless or in despair because you don\'t know what to do next. Or when you feel scared, angry or drained out....',
            value2: 'We can actually dissolve stress, anger and rage as well as fears and discomfort through our body directly in everyday life. This is how nature originally intended it. But for a long time, due to our culture, we no longer have direct access to this natural ability. Today we know how to consciously activate it. And it\'s completely free!'
        }
    },
    purpose: {
        title: 'What’s in it for you?',
        content: '<span style="font-size: 1em; font-weight: bold">YOUR LIFE GETS EASIER!</span>'
    },
    purpose2: {
        title: '',
        content: '<ul class="disc">' +
            '<li>The stress in your everyday life becomes less and you become more relaxed. Your relationships with others will also become less stressful and you will live a healthier life. Because - and this is scientifically proven - over time stress makes us ill .</li>' +
            '<li>Stress reduction will help you, no matter whether you are at school, training for a job or at university, whether you are working, unemployed or retired, or whether you are looking after children, the household or your family.</li>' +
            '</ul>'
    },
    howTo: {
        title: 'And how does it work?',
        content: 'A special approach has been developed to consciously activate our biological ability to dissolve stress, fears and blockages. It is known as Emotional Resolution or ERES® (the English abbreviation is EmRes®) and is taught free of charge by the Institute for Emotional Health (IEG). The conscious activation of this ability through ERES® is easy to learn - also for you.'
    },
    course: {
        title: 'The procedure step by step:',
        step1: {
            title: 'Step 1: Preparation',
            preface: 'To prepare yourself you need to find out if your body actually reacts to anxiety and stress.',
            content:
                '<p>Have you ever experienced that your body reacts when you are angry for example - or have you observed it in others? </p>' +
                '<p>When they are very angry, many people feel that they are getting hot, for example. In addition, the face may turn red, the heart and breathing become faster, muscles tighten.</p>' +
                '<p>When you are angry, sad or otherwise stressed, try out for yourself where within your body you can sense how it’s reacting. Also pay attention to only slightly noticeable unpleasant body reactions, because both weak and strong reactions show the beginning of the natural process of Emotional Resolution (ERES®).</p>' +
                '<p>Once you have tried it and can confirm these feelings in this way, follow on to step 2.</p>'
        },
        step2: {
            title: 'Step 2: Choose an experience',
            preface: 'If you want to find out how to resolve unpleasant emotions with ERES, you need to select a certain stress experience out of your everyday life (anger, fear, sadness, blockages...).',
            content:
                '<p>Which one do you choose? What disturbs you and stresses you in your life? What are you afraid of? Where are your inhibitions?</p>' +
                '<p>Examples:' +
                '<ul class="disc">' +
                '<li>You are angry about something someone said.</li>' +
                '<li>You are feeling down, helpless and discouraged.</li>' +
                '<li>You are afraid of an upcoming exam or have already experienced a blackout.</li>' +
                '<li>You are shy and afraid to talk to others.</li>' +
                '<li>You have experienced fear of heights, fear of flying, fear of spiders, fear of dogs, claustrophobia?</li>' +
                '</ul>' +
                '</p>' +
                '<p>As soon as you get into a situation in your everyday life where you experience something like this, be aware of your emotion and name it. Now it is particularly important that you do not want to get rid of the emotion, but that you accept it and welcome it kindly - no matter how strong or unpleasant it is. Now perceive where the emotion manifests itself in your body. The first part in the body, where unpleasant feelings arise is usually felt directly: for example a pressure in the chest, a pain in the stomach or something else even just slightly unpleasant. It is best to place your hand where you have this feeling.</p>'
        },
        step3: {
            title: 'Step 3: Inner perception',
            preface: 'Now close your eyes! You consciously activate your natural ability to dissolve fear and stress by exclusively observing what you are currently feeling inside your body.',
            content:
                '<p><span class="bold">It is important to:</span>' +
                '<ul class="disc">' +
                '<li>do nothing, do not move! (Stay as passive and immobile as a statue).  Learn to simply observe your feelings during the whole process and learn to accept and welcome every physical sensation that arises.</li>' +
                '<li>let completely go of the situation that previously triggered your emotions! Don\'t think about it anymore and focus your attention exclusively on the sensations inside your body.</li>' +
                '</ul>' +
                '</p>' +
                '<p class="bold">Otherwise it will not work!</p>' +
                '<p>Sometimes it is also necessary just to go to a place where you feel safe enough to quietly close your eyes for a few minutes and only to be aware of the sensations inside your body.</p>'
        },
        step4: {
            title: 'Step 4: Feel your body',
            preface: 'Continue to focus exclusively into your body! Be aware where you can feel and name any other unpleasant reaction in your body',
            content: '<p>\n' +
                'For example, there may be an uncomfortable tingling sensation somewhere in your body, a burning or prickling sensation. The heartbeat may get faster, breathing faster or slower. A pain, pressure, burning, itching or similar feeling may occur in your back or elsewhere. Each of these bodily sensations that you notice, second to the first, shows you that your natural ability to let an unpleasant emotion or stress dissolve itself is currently activated. You can also place your hand over the spot to become more aware of it.</p>' +
                '<p>This second unpleasant sensation in your body, and others that follow it, is present in every stressful emotion we experience in everyday life. With a little practice, we learn to notice them very quickly the moment we close our eyes.</p>' +
                '<p>Once you have tried this several times with different emotions and stresses, you will find out that your body is indeed always reacting to every emotion.</p>'
        },
        step5: {
            title: 'Step 5: Follow your sensations in your body',
            preface: 'Pay attention to the ongoing changes to the feelings in your body. Don’t do anything! Learn to observe but really completely passively! Let all the sensations in your body change all by themselves until they have calmed down! ',
            content: '<p>They can occur one after the other or simultaneously. The normal sequence is that - if you don\'t move or become active in any way - they will change themselves on their own! It does not take long. After two to three minutes - often faster - your body will feel good again and all body reactions will have calmed down.\n</p>' +
                '<p>For this step it is helpful to know:' +
                '<ul class="disc">' +
                '<li>Every stress, every fear, every "I can\'t do this" is associated with its own specific set of sensations in your body. You can think of it as a film that plays over and over again every time you experience a certain emotion. If you use ERES with different emotions, you will notice this.</li>' +
                '<li>Most of the body sensations that occur are usually only briefly unpleasant and change very quickly if you embrace them kindly and welcome them inside you. This also applies to the violent body reactions that sometimes arise.\n</li>' +
                '</ul></p>'
        },
        step6: {
            title: 'Step 6: When everything has calmed down',
            preface: 'Open your eyes as soon as all unpleasant body sensations have calmed down.',
            content: 'Take a few seconds to completely arrive back to outside awareness again. Become aware again of your surroundings and where you are at the moment. How do you feel?'
        },
        step7: {
            title: 'Step 7: Has everything been resolved?',
            preface: 'Do another check to see if everything has dissolved: some emotional problems need a second try.',
            content: '<p>Ask yourself the question: "If I think again about what stressed me or scared me, where I was blocked or desperate - how do I feel about it now?</p>' +
                '<p>Are you just "better"? Is the emotion weaker but not gone?\n' +
                'If so, try again, in exactly the same way. Ask yourself again, "Where else does my emotion manifest itself in my body?” and repeat steps 2-5 exactly as you did the first time.</p>' +
                '<p>If so, try again, in exactly the same way. Ask yourself again, "Where do I feel something unpleasant in my body?" and repeat steps 2-5 exactly as you did the first time.</p>' +
                '<p>If all is well and you feel emotionally balanced after the first run, then you have successfully used your natural ability to regulate. Congratulations!</p>' +
                '<p>You will then notice in everyday life whether everything related to the original stress has actually been resolved as soon as you react calmly in a similar situation.</p>' +
                '<p>It is possible that other underlying emotions will arise that you also find unpleasant. To avoid getting the impression that ERES did not work for you, it is important to name the emotions with which you do ERES. This will help you to distinguish them from other emotions you experience in similarly stressful situations. It is also helpful if you write them down.</p>' +
                '<p>Every other unpleasant emotion we experience needs its own application of ERES. Once you have recognised the effectiveness of ERES, you should use it as often as possible. The more often you do it, the more calm, with less fear, internally free and courageous you will feel!</p>'
        },
        tips: {
            title: 'Tips - if it is not successful the first time',
            1: {
                title: 'You experience very strong unpleasant sensations in your body:',
                content: '<p>Sometimes when you experience very strong emotions, very strong sensations appear just for a short time. These can be: a strong stinging, trembling, shortness of breath, dizziness or nausea...</p>' +
                    '<p>Our spontaneous reaction to this is often to focus on these unpleasant reactions in the body or to open our eyes. This is a pity, because by doing so we interrupt the process. In order that this doesn’t happen to you, it is important for you to observe your reactions passively. Therefore you need to know that even intense unpleasant sensations will change and calm down within a few seconds if you just let them change.</p>'
            },
            2: {
                title: 'What is meant by "observe passively"?',
                content: 'In this guide to ERES® you are repeatedly told to remain passive and really do nothing while you have your eyes closed. The point is not to influence the course of the body\'s sensations in any way: neither by actively breathing, nor by thinking about the initial situation or other similar situations. Any movement is also a form of becoming active. Most of the time we do this unconsciously in order to diminish or avoid feeling unpleasant sensations. This is not necessary because everything changes very quickly in the passive mode anyway and calms down by itself. Any form of influence slows down or prevents the natural process of ERES®.'
            },
            3: {
                title: 'Be consciously aware of external sounds:',
                content: '<p>The application of ERES® is easiest for many of us if we pay part of our attention to things happenings outside ourselves. To do this, consciously notice the sounds around you. Sometimes strong sensations in your body can change faster this way.</p><p>' +
                    'What is the reason for this? If we simultaneously perceive what is happening inside our body AND in our surroundings with our eyes closed, it is most likely that the emotional body reactions stored in our body memory, like in a film, can follow each other undisturbed, change themselves and calm down.' +
                    '</p>'
            },
            4: {
                title: 'You are always in control of what is happening:',
                content: '<p>: If sensations the body that occur are too uncomfortable for you, or you do not feel completely comfortable in the place you are with your eyes closed, you can open your eyes again at any time and thus end the process.</p>' +
                    '<p>As soon as the emotion you wanted to dissolve with ERES® reappears again in your everyday life it is possible to directly start a new attempt to dissolve it.</p>'
            }
        },
        next: {
            title: 'To make yourself even more familiar with ERES®:',
            content: '<p>It is worthwhile to write down every experience you had by applying the Emotional Resolution (ERES®). All you need to do is write down the first three sensations in your body of each respective emotion. You will notice that each emotion has its own signature and sequence and is stored in our body memory like in a library.</p>' +
                '<p>For example, create a notebook for this or dictate your experiences into your smartphone.</p>' +
                '<p>And share them. Nature has organised things in such a way that we often completely forget the permanently resolved fears, stress and inhibitions if we have not written down the experiences or shared them with others. The usefulness of this ability is even better remembered when we tell others how we managed it to dissolve a particular fear or stress.</p>'
        },
        limits: {
            title: 'The limits of ERES® and personal responsibility',
            content: '<p>From the point of view of the IEG, the knowledge of the application of our natural ability to be able to dissolve unpleasant emotions ourselves represents a special opportunity. At the same time, it is important to take personal responsibility for learning the process.</p>'
        },
        shortVersion: {
            title: 'Brief instructions for the application of ERES®',
            content: '<ul class="disc"><li>Pay attention and learn to recognised stress experiences in your everyday life (anger, fear, sadness, blockages...) in order to apply ERES®!</li>' +
                '<li>As soon as you experience an unpleasant emotion, remember that you can apply ERES®, finding yourself in a safe place. Name the emotion and welcome it. Then ask yourself the question, "where am I feeling this in my body right now?" Also welcome any other bodily sensations that arise.</li>' +
                '<li>If you like, put a hand on the place where you perceive an unpleasant body sensation. This enables passive sensing within the body.</li>' +
                '<li>Now close your eyes!</li>' +
                '<li>Where do you notice a second unpleasant sensation in your body? If you wish, place a hand on this spot as well.</li>' +
                '<li>Don’t do anything!</li>' +
                '<li>Observe in a friendly way what happens inside your body and how new sensations within your body make themselves felt one after the other and change again and again! You can also follow these changes with your hands.</li>' +
                '<li>Do this until all body sensations have calmed down! This takes about two to three minutes; sometimes it works faster.</li>' +
                '<li>When there are no more unpleasant sensations within your body, open your eyes!</li>' +
                '<li>Come back to the outside world and now ask yourself: "If I think again about what has just stressed me - how do I feel about it now?</li>' +
                '<li>If you feel "just better" but the emotion is not yet completely resolved, try the identical procedure a second time! Welcome the emotion again and ask yourself the question: "Where do I feel it in my body?"</li>' +
                '<li>If everything appears to be successful after the first session and you feel emotionally balanced, you have successfully used ERES®. In similar everyday situations you will experience the confirmation: You have managed to stay calm this time.</li></ul>'
        }
    },
    disclaimer: {
        title: 'Disclaimer',
        content: '<p>ERES is an effective form of first aid when stress and other unpleasant emotions arise in everyday life. At the same time, it does not replace any form of therapy or consultation with a doctor. To perform the procedure described here is entirely at your own risk.</p>' +
            '<p>All information is provided without warranty or guarantee on the part of the authors or the IEG. Any liability of the IEG or its representatives for personal injury, property damage or financial loss is therefore excluded.\n</p>',
        contentShort: '<p>ERES is an effective form of first aid when stress and other unpleasant emotions arise in everyday life. At the same time, it does not replace any form of therapy or consultation with a doctor. To perform the procedure described here is entirely at your own risk.</p>'
    },
    support: {
        title: 'I would like to support IEG',
        content: '<p>With this website, we would like to make the ERES manual available to all people in their own language and also spread the knowledge about this resource with other initiatives. As a non-profit association, we depend on donations to make this possible. We would be happy if you would support us with a donation to the following account:</p>' +
            'Recipient of payment: Institut für Emotionale Gesundheit e. V.<br>' +
            'IBAN: DE13 4786 0125 0530 8813 00<br>' +
            'BIC: GENODEM1GTL<br>' +
            '<p>If you would like a donation receipt, please be sure to include your name and full address with the transfer so we can send it to you.</p>'
    }
}