<template>
  <div id="about-us-card">
    <div id="about-us" class="card">
      <div class="card-content">
        <a class="modal-trigger" href="#about-us-modal">
          <div class="card-image">
            <img src="../assets/images/alp-duran-SwMgX1d0Kmw-unsplash.jpg"
                 style="height: 110px; object-fit: cover; object-position: 50% 25%">
          </div>
          <span class="card-title grey-text text-darken-3 lighten-3">{{ $t('sidePanel.aboutUs.title') }}</span>
          <p class="grey-text text-darken-3 lighten-3 short" v-html="$t('sidePanel.aboutUs.content')"></p>
        </a>
      </div>
      <div class="card-action">
        <a class="modal-trigger" href="#about-us-modal">{{ $t('sidePanel.aboutUs.link') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsCard"
}
</script>

<style scoped>
.sidepanel .card .card-content {
  padding: 24px 12px 24px 16px;
}

.short {
  max-height: 153px;
  overflow: hidden;
  line-height: initial;
}
</style>